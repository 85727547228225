import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PageviewIcon from '@material-ui/icons/Pageview';
import Badge from '@material-ui/core/Badge';
import Paper from '@material-ui/core/Paper';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  icon: {
    height: 32,
    width: 32
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function currencyFormat(num) {
  return (num === undefined) ? 0 : String(parseInt(num)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function percentageCalculater(total, number) {
  return (Number(number * 100 / total).toFixed(0))
}

class TotalClients extends Component {

    componentDidMount() { 
    }
  
    render() {
        const {total_clients} = this.props;

        const RenderTotalClients = () => {

            const classes = useStyles();
            const [open, setOpen] = React.useState(false);

            const handleClickOpen = () => {
              setOpen(true);
            };
            const handleClose = () => {
              setOpen(false);
            };
          
            return (
              <Card
                className={clsx(classes.root)}
              >
                <CardContent>
                  <Grid
                    container
                    justify="space-between"
                  >
                    <Grid item>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                        noWrap
                      >
                        Clientes Totales
                        {(() => {
                          if(total_clients){
                            if(total_clients.data_total_clients){
                              return(
                                <IconButton color="inherit">
                                  <Badge badgeContent={0} color="secondary" onClick={handleClickOpen}>
                                    <PageviewIcon />
                                  </Badge>
                                </IconButton>              
                              );
                            }
                          }

                        })()}
                      </Typography>
                      <div>
                        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            Detalle por Sucursal
                          </DialogTitle>
                          <DialogContent dividers>
                          <div className={classes.root}>
                            <Paper className={classes.paper}>
                              <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">Sucursal</TableCell>
                                    <TableCell align="center">Clientes</TableCell>
                                    <TableCell align="center">%</TableCell>
                                  </TableRow>
                                </TableHead>
                                {(() => {
                                  if(total_clients){
                                    if(total_clients.data_total_clients){
                                      return(
                                        <TableBody>
                                        {total_clients.data_total_clients.map(result => (
                                          <TableRow
                                            hover
                                            key={result.sucursal}
                                          >
                                            <TableCell>{result.sucursal}</TableCell>
                                            <TableCell>{`${currencyFormat( Number(result.clients[0][0].clientes_totales) )}`}</TableCell>
                                            <TableCell>{`${percentageCalculater(Number(total_clients.total_clients), Number(result.clients[0][0].clientes_totales) )}`}%</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>                 
                                      );
                                    }
                                  }

                                })()}
                              </Table>
                            </Paper>
                          </div>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose} color="primary">
                              Cerrar
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                      <Typography variant="h4">{(total_clients) ? `${currencyFormat(total_clients.total_clients)}`: 0}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          };

        // Render principal
    return (      
        <RenderTotalClients />
      );
    }
}


function mapStateToProps(state) {
  return {
      total_clients: state.reports_average_consume
  }
}
  
export default connect(mapStateToProps)(TotalClients);