
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'SERVICE_TYPE_SALES':

            const service_type_sales = action.payload_service_type_sales;
            copy.service_type_sales=JSON.parse(JSON.stringify(service_type_sales));

          return copy
        
        default:
            return state;
      }
  }