
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'TOTAL_SALES':
            copy.sale_total = undefined;

            let data = action.payload_total_sales;
            let venta = 0;
            copy.data_sale_total = data;

            data.forEach(function(element){
              venta = venta + element.sales[0][0].venta_total
            })

            copy.sale_total=venta;
            copy.sale_total_refresh = false;

          return copy
        case 'SALES_COST':
            copy.total_cost = undefined;
            

            let data_cost = action.payload_sales_cost;
            let venta_cost = 0;
            copy.data_total_cost = data_cost;

            data_cost.forEach(function(element){
              venta_cost = venta_cost + element.cost[0][0].costos_totales
            })

            copy.total_cost=venta_cost;
            copy.total_cost_refresh = false;

          return copy
        case 'SALES_TOTALS':

            let data_sales_totals = action.payload_sales_totals;
            let venta_sales_totals = 0;

            copy.data_sales_totals = data_sales_totals;

            data_sales_totals.forEach(function(element){
              venta_sales_totals = venta_sales_totals + element.accounts[0][0].cuentas_totales
            })

            copy.sales_totals=venta_sales_totals;

          return copy
        case 'TOTAL_CLIENTS':

            let data_total_clients = action.payload_total_clients;
            let total_clients = 0;

            copy.data_total_clients = data_total_clients;

            data_total_clients.forEach(function(element){
              total_clients = total_clients + element.clients[0][0].clientes_totales
            })

            copy.total_clients=total_clients;

          return copy
        case 'TOP_WORST_FOODS':

            copy.top_food = undefined;
            copy.worst_food = undefined;
            const top_food = action.payload_food;
            copy.data_top_food = JSON.parse(JSON.stringify(top_food));
            
            var dataObject_food= top_food.map((data) => {
              return data.data[0];
            })
            
            

            let dataObject_food_worst= top_food.map((data) => {
              return data.data_worst[0];
              })
              
            let aggregatedDataObject_food = []
            let aggregatedDataObject_food_worst = []
            
            dataObject_food.forEach(function(location){
                location.forEach(function(element){
                    aggregatedDataObject_food.push(element)
                })
            })

            dataObject_food_worst.forEach(function(location){
                location.forEach(function(element){
                    aggregatedDataObject_food_worst.push(element)
                })
            })

            
            
            let results_food = aggregatedDataObject_food.reduce(function(res, obj) {
                if (!(obj.codigo in res))
                    res.__array.push(res[obj.codigo] = obj);
                else {
                    res[obj.codigo].cantidad += obj.cantidad;
                    res[obj.codigo].total_facturado += obj.total_facturado;
                }
                return res;
            }, {__array:[]}).__array
                            .sort(function(a,b) { return b.total_facturado - a.total_facturado; });

            let results_food_worst = aggregatedDataObject_food_worst.reduce(function(res, obj) {
                if (!(obj.codigo in res))
                    res.__array.push(res[obj.codigo] = obj);
                else {
                    res[obj.codigo].cantidad += obj.cantidad;
                    res[obj.codigo].total_facturado += obj.total_facturado;
                }
                return res;
            }, {__array:[]}).__array
                            .sort(function(a,b) { return a.total_facturado - b.total_facturado; });

            copy.top_food=results_food;
            copy.worst_food=results_food_worst;

          return copy
        case 'TOP_WORST_DRINKS':
            copy.top_drink = undefined;
            copy.worst_drink = undefined;
            let top_worst_drink = action.payload_drink;
            let dataObject_drink= top_worst_drink.map((data) => {
              return data.data[0];
              })

            let dataObject_drink_worst= top_worst_drink.map((data) => {
              return data.data_worst[0];
              })
              
            let aggregatedDataObject_drink = []
            let aggregatedDataObject_drink_worst = []
            
            dataObject_drink.forEach(function(location){
                location.forEach(function(element){
                    aggregatedDataObject_drink.push(element)
                })
            })

            dataObject_drink_worst.forEach(function(location){
                location.forEach(function(element){
                    aggregatedDataObject_drink_worst.push(element)
                })
            })
            
            let results_drink = aggregatedDataObject_drink.reduce(function(res, obj) {
                if (!(obj.codigo in res))
                    res.__array.push(res[obj.codigo] = obj);
                else {
                    res[obj.codigo].cantidad += obj.cantidad;
                    res[obj.codigo].total_facturado += obj.total_facturado;
                }
                return res;
            }, {__array:[]}).__array
                            .sort(function(a,b) { return b.total_facturado - a.total_facturado; });

            let results_drink_worst = aggregatedDataObject_drink_worst.reduce(function(res, obj) {
                if (!(obj.codigo in res))
                    res.__array.push(res[obj.codigo] = obj);
                else {
                    res[obj.codigo].cantidad += obj.cantidad;
                    res[obj.codigo].total_facturado += obj.total_facturado;
                }
                return res;
            }, {__array:[]}).__array
                            .sort(function(a,b) { return a.total_facturado - b.total_facturado; });

            copy.top_drink=results_drink;
            copy.worst_drink=results_drink_worst;

          return copy
        case 'SALES_CATEGO':

            let sales_catego = action.payload_sales_catego;
            copy.sales_catego=sales_catego;

          return copy
        case 'SALES_WAITER':

            let sales_waiter = action.payload_waiter;
            copy.sales_waiter=sales_waiter;

          return copy
        case 'SALES_PAYMENT_METHOD':

            let sales_payment_method = action.payload_payment;
            copy.sales_payment_method=sales_payment_method;

          return copy
        case 'CANCEL_SALES':
            copy.cancel_sales = undefined;

            let cancel_sales = action.payload_cancel_sales;
            var dataObject_cancel= cancel_sales.map((data) => {
                return data.data[0];
            });

            var data_cancel_sales = [];

            let aggregatedDataObject_cancel = [];

            dataObject_cancel.forEach(function(location){
                location.forEach(function(element){
                  aggregatedDataObject_cancel.push(element)
                })
            })

            data_cancel_sales.push({
                name: 'Descuentos',
                y: aggregatedDataObject_cancel.reduce((acc, c) => {
                    return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.descuentos_totales).toFixed(2)) || 0);
                  }, 0) || 0
            });
            data_cancel_sales.push({
                name: 'Cortesías',
                y: aggregatedDataObject_cancel.reduce((acc, c) => {
                    return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.cortesias_totales).toFixed(2)) || 0);
                  }, 0) || 0
            });
            data_cancel_sales.push({
                name: 'Cancelaciones',
                y: aggregatedDataObject_cancel.reduce((acc, c) => {
                    return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.cancelaciones_totales).toFixed(2)) || 0);
                  }, 0) || 0
            });
            data_cancel_sales.push({
                name: 'Anulaciones',
                y: aggregatedDataObject_cancel.reduce((acc, c) => {
                    return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.anulaciones_totales).toFixed(2)) || 0);
                  }, 0) || 0
            });



            copy.cancel_sales=data_cancel_sales;

          return copy
        case 'AREA_SALES':

            let area_sales = action.payload_area_sales;
            copy.area_sales=area_sales;

          return copy
        case 'TYPE_SALES':
            
            copy.type_sales = undefined;
            
            let type_sales = action.payload_type_sales;
            
            let dataObject= type_sales.map((data) => {
              return data.data[0];
            })
            
            let aggregatedDataObject = [];
            let data_type_sales = [];
            
            dataObject.forEach(function(location){
              location.forEach(function(element){
                aggregatedDataObject.push(element)
              })
            })
            
            let results_type = aggregatedDataObject.reduce(function(restype, objtype) {
              if (!(objtype.clase in restype))
                    restype.__array.push(restype[objtype.clase] = objtype);
                else {
                  restype[objtype.clase].cantidad += objtype.cantidad;
                    restype[objtype.clase].total_facturado += objtype.total_facturado;
                  }
                return restype;
              }, {__array:[]}).__array
              .sort(function(a,b) { return b.total_facturado - a.total_facturado; });

              
              results_type.forEach(function(element){
                data_type_sales.push({
                  name: element.tipo,
                  y: parseFloat(Number(element.total_facturado).toFixed(2)),
                  info: 'Sucursal'
                })
              })
              copy.type_sales=data_type_sales;
              
              return copy
            case 'COMPARE_PREVIOUS_PERIOD':

                copy.compare_previous_period = undefined;
    
                let data_compare_previous_period = action.payload_compare_previous_period;
                let venta_total_current = 0;
                let venta_total_previous_period = 0;
                let percentaje_current = 0;

                data_compare_previous_period.forEach(function(element){
                  venta_total_current = venta_total_current + element.sales[0][0].venta_total
                })
                
                data_compare_previous_period.forEach(function(element){
                  venta_total_previous_period = venta_total_previous_period + element.sales[0][0].venta_total_previa
                })

                percentaje_current = (venta_total_current / venta_total_previous_period) - 1;

                copy.compare_previous_period = {
                  actual: venta_total_current,
                  previo: venta_total_previous_period,
                  dates: data_compare_previous_period[0].dates,
                  percentaje_current: percentaje_current
                };
    
              return copy
            case 'COMPARE_PREVIOUS_PERIOD_YEAR':

                copy.compare_previous_period_year = undefined;

                let data_compare_previous_period_year = action.payload_compare_previous_period_year;
                let venta_total_current_year = 0;
                let venta_total_previous_period_year = 0;
                let percentaje_current_year = 0;

                data_compare_previous_period_year.forEach(function(element){
                  venta_total_current_year = venta_total_current_year + element.sales[0][0].venta_total;
                })
                
                data_compare_previous_period_year.forEach(function(element){
                  venta_total_previous_period_year = venta_total_previous_period_year + element.sales[0][0].venta_total_ano_pasado;
                })

                percentaje_current_year = (venta_total_current_year / venta_total_previous_period_year) - 1;

                copy.compare_previous_period_year = {
                  actual: venta_total_current_year,
                  previo_ano: venta_total_previous_period_year,
                  dates: data_compare_previous_period_year[0].dates,
                  percentaje_current_year: percentaje_current_year
                };
    
              return copy
            default:
                return state;
      }
  }