
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'TYPE_SALES':
            
            copy.type_sales = undefined;
            
            let type_sales = action.payload_type_sales;
            
            let dataObject= type_sales.map((data) => {
              return data.data[0];
            })
            
            let aggregatedDataObject = [];
            let data_type_sales = [];
            
            dataObject.forEach(function(location){
              location.forEach(function(element){
                aggregatedDataObject.push(element)
              })
            })
            
            let results_type = aggregatedDataObject.reduce(function(restype, objtype) {
              if (!(objtype.clase in restype))
                    restype.__array.push(restype[objtype.clase] = objtype);
                else {
                  restype[objtype.clase].cantidad += objtype.cantidad;
                    restype[objtype.clase].total_facturado += objtype.total_facturado;
                  }
                return restype;
              }, {__array:[]}).__array
              .sort(function(a,b) { return b.total_facturado - a.total_facturado; });

              
              results_type.forEach(function(element){
                data_type_sales.push({
                  name: element.tipo,
                  y: parseFloat(Number(element.total_facturado).toFixed(2)),
                  info: 'Sucursal'
                })
              })
              copy.type_sales=data_type_sales;

              const family_types = JSON.stringify(JSON.parse(JSON.stringify(data_type_sales)));
              localStorage.setItem('Family', family_types);              
              
              return copy
            
          default:
              return state;
      }
  }