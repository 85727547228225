import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
    Typography,
    AppBar,
    Tabs,
    Tab,
    Box
  } from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    widthTab: {
        minWidth: '33%'
    }
  }));


class SalesTime extends Component {

    componentDidMount() { 
    }
  
    render() {

        const {salesHour, accountsHour, clientsHour} = this.props;

        let options = {
            title: {
                text: 'Ventas por hora'
            },
            chart: {
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            }
        }
        let optionsAccounts = {
            title: {
                text: 'Cuentas por hora'
            },
            chart: {
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            }
        }
        let optionsClients = {
            title: {
                text: 'Clientes por hora'
            },
            chart: {
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            }
        }
        if(salesHour){
            if(salesHour.sales_hour) {

                const hora = [];
                const horaFinal = [];
                let minHour = 0;
                let maxHour = 23;
                let series = [];
                var data = [];

                salesHour.sales_hour.forEach(function(element){
                    element.data[0].forEach(function(value){
                        hora.push(value.hora);
                    })
                })

                minHour = Math.min.apply(null, hora);
                maxHour = Math.max.apply(null, hora);

                let i;
                for (i = minHour; i <= maxHour; i++) {
                    if(i < 10){
                        horaFinal.push(("0" + i).toString());
                    }else{
                        horaFinal.push(i.toString());
                    }
                    
                }

                salesHour.sales_hour.forEach(function(sales){
                    data = []
                    horaFinal.forEach(function(hour){
                        if(sales.data[0].find(element => element.hora === hour)){
                          data.push(sales.data[0].find(element => element.hora === hour).totales);  
                        }else{
                          data.push(0);
                        }

                    })

                    series.push({
                        name: sales.sucursal,
                        data: data
                    })
                    
                })

                options = {
                    chart: {
                        scrollablePlotArea: {
                            minWidth: 700
                        }
                    },
                
                    title: {
                        text: 'Ventas por hora'
                    },
                
                    subtitle: {
                        text: 'Detalle por sucursal'
                    },
                
                    xAxis: {
                        type: 'datetime',
                        tooltip:
                        {
                            enabled: false
                        },
                        title: {
                            text: 'Linea de tiempo'
                        }
                    },
                
                    yAxis: {
                        tickWidth: 1,
                        title: {
                            text: 'Monto de venta ($) MXN'
                        },
                        lineWidth: 1,
                        opposite: true
                    },
                
                    legend: {
                        align: 'left',
                        verticalAlign: 'top',
                        borderWidth: 0
                    },
                    tooltip: {
                        valueSuffix: ' (MXN)',
                        valuePrefix:'$',
                        split: true,
                        xDateFormat: '%H:%M:%S'
                        // enabled: false
                    },
                
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                            marker: {
                                lineWidth: 1
                            },
                            pointInterval: 3600000, // one hour
                            pointStart: Date.UTC(2019, 6, 8, minHour, 0, 0)
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: series
                }
            }
        }

        if(accountsHour){
            if(accountsHour.accounts_hour) {

                const horaAccount = [];
                const horaFinalAccount = [];
                let minHourAccount = 0;
                let maxHourAccount = 23;
                let seriesAccount = [];
                var dataAccount = [];

                accountsHour.accounts_hour.forEach(function(element){
                    element.data[0].forEach(function(value){
                        horaAccount.push(value.hora);
                    })
                })

                minHourAccount = Math.min.apply(null, horaAccount);
                maxHourAccount = Math.max.apply(null, horaAccount);

                let i;
                for (i = minHourAccount; i <= maxHourAccount; i++) {
                    if(i < 10){
                        horaFinalAccount.push(("0" + i).toString());
                    }else{
                        horaFinalAccount.push(i.toString());
                    }
                    
                }

                accountsHour.accounts_hour.forEach(function(sales){
                    dataAccount = []
                    horaFinalAccount.forEach(function(hour){
                        if(sales.data[0].find(element => element.hora === hour)){
                            dataAccount.push(sales.data[0].find(element => element.hora === hour).totales);  
                        }else{
                            dataAccount.push(0);
                        }

                    })

                    seriesAccount.push({
                        name: sales.sucursal,
                        data: dataAccount
                    })
                    
                })

                optionsAccounts = {
                    chart: {
                        scrollablePlotArea: {
                            minWidth: 700
                        }
                    },
                
                    title: {
                        text: 'Cuentas por hora'
                    },
                
                    subtitle: {
                        text: 'Detalle por sucursal'
                    },
                
                    xAxis: {
                        type: 'datetime',
                        tooltip:
                        {
                            enabled: false
                        },
                        title: {
                            text: 'Linea de tiempo'
                        }
                    },
                
                    yAxis: {
                        tickWidth: 1,
                        title: {
                            text: 'Cantidad de cuentas'
                        },
                        lineWidth: 1,
                        opposite: true
                    },
                
                    legend: {
                        align: 'left',
                        verticalAlign: 'top',
                        borderWidth: 0
                    },
                    tooltip: {
                        valueSuffix: ' Cuentas',
                        split: true,
                        xDateFormat: '%H:%M:%S'
                        // enabled: false
                    },
                
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                            marker: {
                                lineWidth: 1
                            },
                            pointInterval: 3600000, // one hour
                            pointStart: Date.UTC(2019, 6, 8, minHourAccount, 0, 0)
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: seriesAccount
                }
            }
        }

        if(clientsHour){
            if(clientsHour.clients_hour) {

                const horaClient = [];
                const horaFinalClient = [];
                let minHourClient = 0;
                let maxHourClient = 23;
                let seriesClient = [];
                var dataClient = [];

                clientsHour.clients_hour.forEach(function(element){
                    element.data[0].forEach(function(value){
                        horaClient.push(value.hora);
                    })
                })

                minHourClient = Math.min.apply(null, horaClient);
                maxHourClient = Math.max.apply(null, horaClient);

                let i;
                for (i = minHourClient; i <= maxHourClient; i++) {
                    if(i < 10){
                        horaFinalClient.push(("0" + i).toString());
                    }else{
                        horaFinalClient.push(i.toString());
                    }
                    
                }

                clientsHour.clients_hour.forEach(function(sales){
                    dataClient = []
                    horaFinalClient.forEach(function(hour){
                        if(sales.data[0].find(element => element.hora === hour)){
                            dataClient.push(sales.data[0].find(element => element.hora === hour).totales);  
                        }else{
                            dataClient.push(0);
                        }

                    })

                    seriesClient.push({
                        name: sales.sucursal,
                        data: dataClient
                    })
                    
                })

                optionsClients = {
                    chart: {
                        scrollablePlotArea: {
                            minWidth: 700
                        }
                    },
                
                    title: {
                        text: 'Clientes por hora'
                    },
                
                    subtitle: {
                        text: 'Detalle por sucursal'
                    },
                
                    xAxis: {
                        type: 'datetime',
                        tooltip:
                        {
                            enabled: false
                        },
                        title: {
                            text: 'Linea de tiempo'
                        }
                    },
                
                    yAxis: {
                        tickWidth: 1,
                        title: {
                            text: 'Cantidad de comensales'
                        },
                        lineWidth: 1,
                        opposite: true
                    },
                
                    legend: {
                        align: 'left',
                        verticalAlign: 'top',
                        borderWidth: 0
                    },
                    tooltip: {
                        valueSuffix: ' Comensales',
                        split: true,
                        xDateFormat: '%H:%M:%S'
                        // enabled: false
                    },
                
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                            marker: {
                                lineWidth: 1
                            },
                            pointInterval: 3600000, // one hour
                            pointStart: Date.UTC(2019, 6, 8, minHourClient, 0, 0)
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: seriesClient
                }
            }
        }

        const RenderSalesTime = () => {
            const classes = useStyles();
            const [value, setValue] = React.useState(0);
            const handleChange = (event, newValue) => {
                setValue(newValue);
            };

            return (
                <div style={{ textAlign: 'center' }}>
                    <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab className={classes.widthTab} label="Ventas" {...a11yProps(0)} />
                        <Tab className={classes.widthTab} label="Cuentas" {...a11yProps(1)} />
                        <Tab className={classes.widthTab} label="Comensales" {...a11yProps(2)} />
                    </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsAccounts}
                    />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsClients}
                    />
                    </TabPanel>
                </div>
            );
        };

        // Render principal
        return (
            <RenderSalesTime />
        );  
    }

}

function mapStateToProps(state) {
    return {
        salesHour: state.reports_sales_hour,
        accountsHour: state.reports_accounts_hour,
        clientsHour: state.reports_clients_hour
    }
}

export default connect(mapStateToProps)(SalesTime);