import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

import Login from './Login';
import Statistics from './Statistics';
import Reports from './Reports';
import Dashboard from './Dashboard';

import './../css/datatables.css';
import './../css/material.css';

function isLoggedIn() {
    return (localStorage.getItem('token_prod') !== null)
  }

class App extends Component {

    componentDidMount() {
    }    

    render() {

        return (
            <div>
                <div className="container">
                    <BrowserRouter>
                    <div>
                        {/* <Route exact path="/login" component={Login} onEnter={redirectIfLoggedIn} /> */}

                        <Route exact path="/login" render={() => (
                            isLoggedIn() ? (
                                <Redirect to="/"/>
                            ) : (
                                <Login />
                            )
                        )}/>

                        <Route exact path="/" render={() => (
                            isLoggedIn() ? (
                                <Dashboard />
                            ) : (                                
                                <Redirect to="/login"/>
                            )
                        )}/>
                        {/* <Route exact path="/" component={Dashboard} /> */}
                        {/* <Route exact path="/sales" component={Sales} /> */}
                        {/* <Route exact path="/statistics" component={Statistics} /> */}

                        <Route exact path="/statistics" render={() => (
                            isLoggedIn() ? (
                                <Statistics />
                            ) : (                                
                                <Redirect to="/login"/>
                            )
                        )}/>

                        {/* <Route exact path="/reports" component={Reports} /> */}

                        <Route exact path="/reports" render={() => (
                            isLoggedIn() ? (
                                <Reports />
                            ) : (                                
                                <Redirect to="/login"/>
                            )
                        )}/>

                        {/* <Route path="/reports/new" component={ReportsNew} /> */}
                    </div>
                    </BrowserRouter>
                </div>
            </div>
        );
    }
};

export default connect(null, actions)(App);