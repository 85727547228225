
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'FACTURA_SALES':

            const factura_sales = action.payload_factura_sales;
            copy.factura_sales = JSON.parse(JSON.stringify(factura_sales));

          return copy
        
        default:
            return state;
      }
  }