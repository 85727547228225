import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    widthTab: {
        minWidth: '33%'
    }
  }));


class AreaSales extends Component {

    componentDidMount() { 
    }
  
    render() {
        const {areaSales, accountsArea, clientsArea} = this.props;

        let options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                animation: false
            },
            title: {
                text: 'Venta por área'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            credits: {
                enabled: false
            }
        }
        
        let optionsAccounts = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                animation: false
            },
            title: {
                text: 'Cuentas por área'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            credits: {
                enabled: false
            }
        }
        
        let optionsClients = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                animation: false
            },
            title: {
                text: 'Clientes por área'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            credits: {
                enabled: false
            }
        }

        if(areaSales){
            if(areaSales.area_sales) {
                
                let area_sales = JSON.parse(JSON.stringify(areaSales.area_sales));
                const dataObject= area_sales.map((data) => {
                    return data.data[0];
                });
                const data_area_sales = [];

                const aggregatedDataObject = [];
                

                dataObject.forEach(function(location){
                    location.forEach(function(element){
                        aggregatedDataObject.push(element)
                    })
                })

                if (area_sales[0].platform === "Restpro"){

                    // console.log("Data: ", aggregatedDataObject);
                    let results_area_sales = aggregatedDataObject.reduce(function(res, obj) {
                        if (!(obj.clase in res))
                            res.__array.push(res[obj.clase] = obj);
                        else {
                            res[obj.clase].totales += obj.totales;
                        }
                        return res;
                    }, {__array:[]}).__array
                                    .sort(function(a,b) { return b.totales - a.totales; });

                    results_area_sales.forEach(function(element){
                        data_area_sales.push({
                            name: String(element.clase),
                            y: parseFloat(Number(element.totales).toFixed(2))
                        })

                    })

                }else{
                    data_area_sales.push({
                        name: 'Salón',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_salon).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_area_sales.push({
                        name: 'Mostrador',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_mostrador).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_area_sales.push({
                        name: 'Domicilio',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_domicilio).toFixed(2)) || 0);
                          }, 0) || 0
                    });
                }

                

                options = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        animation: false
                    },
                    title: {
                        text: 'Venta por área'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_area_sales
                    }]
                }
            }
        }

        if(accountsArea){
            if(accountsArea.accounts_area) {
                
                let accounts_area = JSON.parse(JSON.stringify(accountsArea.accounts_area));
                const dataObjectAccounts= accounts_area.map((data) => {
                    return data.data[0];
                });
                const data_accounts_area = [];

                const aggregatedDataObjectAccounts = [];
                

                dataObjectAccounts.forEach(function(location){
                    location.forEach(function(element){
                        aggregatedDataObjectAccounts.push(element)
                    })
                })

                if (accounts_area[0].platform === "Restpro"){

                    let results_area_accounts = aggregatedDataObjectAccounts.reduce(function(res, obj) {
                        if (!(obj.clase in res))
                            res.__array.push(res[obj.clase] = obj);
                        else {
                            res[obj.clase].totales += obj.totales;
                        }
                        return res;
                    }, {__array:[]}).__array
                                    .sort(function(a,b) { return b.totales - a.totales; });

                    results_area_accounts.forEach(function(element){
                        data_accounts_area.push({
                            name: String(element.clase),
                            y: parseFloat(Number(element.totales).toFixed(2))
                        })

                    })

                }else{
                    data_accounts_area.push({
                        name: 'Salón',
                        y: aggregatedDataObjectAccounts.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_salon).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_accounts_area.push({
                        name: 'Mostrador',
                        y: aggregatedDataObjectAccounts.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_mostrador).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_accounts_area.push({
                        name: 'Domicilio',
                        y: aggregatedDataObjectAccounts.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_domicilio).toFixed(2)) || 0);
                          }, 0) || 0
                    });
                }

                

                optionsAccounts = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        animation: false
                    },
                    title: {
                        text: 'Cuentas por área'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_accounts_area
                    }]
                }
            }
        }

        if(clientsArea){
            if(clientsArea.clients_area) {
                
                let clients_area = JSON.parse(JSON.stringify(clientsArea.clients_area));
                const dataObjectClients= clients_area.map((data) => {
                    return data.data[0];
                });
                const data_clients_area = [];

                const aggregatedDataObjectClients = [];
                

                dataObjectClients.forEach(function(location){
                    location.forEach(function(element){
                        aggregatedDataObjectClients.push(element)
                    })
                })

                if (clients_area[0].platform === "Restpro"){

                    let results_area_clients = aggregatedDataObjectClients.reduce(function(res, obj) {
                        if (!(obj.clase in res))
                            res.__array.push(res[obj.clase] = obj);
                        else {
                            res[obj.clase].totales += obj.totales;
                        }
                        return res;
                    }, {__array:[]}).__array
                                    .sort(function(a,b) { return b.totales - a.totales; });

                    results_area_clients.forEach(function(element){
                        data_clients_area.push({
                            name: String(element.clase),
                            y: parseFloat(Number(element.totales).toFixed(2))
                        })

                    })

                }else{
                    data_clients_area.push({
                        name: 'Salón',
                        y: aggregatedDataObjectClients.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_salon).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_clients_area.push({
                        name: 'Mostrador',
                        y: aggregatedDataObjectClients.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_mostrador).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_clients_area.push({
                        name: 'Domicilio',
                        y: aggregatedDataObjectClients.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_domicilio).toFixed(2)) || 0);
                          }, 0) || 0
                    });
                }

                

                optionsClients = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        animation: false
                    },
                    title: {
                        text: 'Clientes por área'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_clients_area
                    }]
                }
            }
        }

        

        const RenderAreaSales = () => {
            const classes = useStyles();
            const [value, setValue] = React.useState(0);
            const handleChange = (event, newValue) => {
                setValue(newValue);
            };

            return (
                <div style={{ textAlign: 'center' }}>
                    <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab className={classes.widthTab} label="Ventas" {...a11yProps(0)} />
                        <Tab className={classes.widthTab} label="Cuentas" {...a11yProps(1)} />
                        <Tab className={classes.widthTab} label="Comensales" {...a11yProps(2)} />
                    </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsAccounts}
                    />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsClients}
                    />
                    </TabPanel>
                </div>
            );
        };

        // Render principal
        return (
        
            <RenderAreaSales />
        );  
    }

}

function mapStateToProps(state) {
    return {
        areaSales: state.reports_area,
        accountsArea: state.reports_accounts_area,
        clientsArea: state.reports_clients_area
    }
}

export default connect(mapStateToProps)(AreaSales);