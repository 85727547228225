
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'SALES_CATEGO':

            const sales_catego = action.payload_sales_catego;
            copy.sales_catego = JSON.parse(JSON.stringify(sales_catego));

          return copy
        
        default:
            return state;
      }
  }