import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import clsx from 'clsx';
import * as actions from '../actions';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import {red, grey} from '@material-ui/core/colors';
import { mainListItems } from './listItems';
import SalesCost from './sales/SalesCost';
import TotalSales from './sales/TotalSales';
import SalesTime from './sales/SalesTime';
import SaleTotal from './sales/boxes/SaleTotal';
import SalesTotals from './sales/boxes/SalesTotals';
import TotalClients from './sales/boxes/TotalClients';
import AverageAccount from './sales/boxes/AverageAccount';
import AverageConsume from './sales/boxes/AverageConsume';
import FilterButtons from './common/FilterButtons';
import FilterButtonsMobile from './common/FilterButtonsMobile';
import Compare from './sales/Compare';
import Hidden from '@material-ui/core/Hidden';
// import DateSelector from './common/DateSelector'
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportdata from 'highcharts/modules/export-data';

HC_exporting(Highcharts);
HC_exportdata(Highcharts);

Highcharts.setOptions({
  lang: {
      decimalPoint: '.',
      thousandsSep: ','
  }
});

const muiTheme = createMuiTheme({
    palette: {
        primary: red,
        secondary: grey
    }
  });

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://ambit.com.mx/">
        Ambit Technology
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    );
  }

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '100%',
  },
}));


class Dashboard extends Component {

  constructor(props) {
		  super(props);
      this.onLogout = this.onLogout.bind(this);
    }

    onLogout() {
        localStorage.removeItem('initialLoad');
        localStorage.removeItem('token_prod');
        this.props.history.push('/login');
        window.location.reload();
    }

    componentDidMount() {
        if(localStorage.getItem('initialLoad') === null){
          if(localStorage.getItem('filterDate') === null){
              this.props.get_reports('ano');
          }else{
              this.props.get_reports(localStorage.getItem('filterDate'));
          }
          localStorage.setItem('initialLoad', true);
        }        
    } 
    
    render() {

        

        const RenderDashboard = () => {

          const classes = useStyles();
          const [open, setOpen] = React.useState(false);
          const handleDrawerOpen = () => {
              setOpen(true);
          };
          const handleDrawerClose = () => {
              setOpen(false);
          };
          const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

            return (
                <ThemeProvider theme={muiTheme}>
                <div className={classes.root}>
                <CssBaseline />
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" className={classes.title}>
                        Ventas
                    </Typography>
                    <FilterButtons />
                    <IconButton color="inherit">
                        <Badge badgeContent={0} color="secondary">
                        <ExitToAppIcon 
                        onClick={this.onLogout}                        
                        />
                        </Badge>
                    </IconButton>
                    </Toolbar>
                </AppBar>
                <Hidden smDown>
                <Drawer
                    variant="permanent"
                    classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                    </div>
                    <Divider />
                    <List>{mainListItems}</List>
                    <Divider />
                </Drawer>
                </Hidden>
                <Hidden mdUp>
                <Drawer
                    variant="temporary"
                    classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                    </div>
                    <Divider />
                    <List>{mainListItems}</List>
                    <Divider />
                    {(() => {
                    if({open}.open){
                        return(
                        <FilterButtonsMobile />                   
                        );
                    }

                    })()}
                    
                    {/* <List>{secondaryListItems}</List> */}
                </Drawer>
                </Hidden>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {/* Sales Cost */}
                        <Grid item xs={12} md={12} lg={12}>
                        <Paper className={fixedHeightPaper}>
                            <SalesCost />
                        </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                        <Paper className={fixedHeightPaper}>
                            <SaleTotal />
                            <Divider />
                            <SalesTotals />
                            <Divider />
                            <TotalClients />
                        </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                        <Paper className={fixedHeightPaper}>
                            <AverageAccount />
                            <Divider />
                            <AverageConsume />
                        </Paper>
                        </Grid>
                        {/* Total Sales */}
                        <Grid item xs={12} md={6} lg={4}>
                        <Paper className={fixedHeightPaper}>
                            <TotalSales />
                        </Paper>
                        </Grid>

                        {(() => {
                          if(localStorage.getItem('platform') === "Restbar"){
                            return(
                              <Grid item xs={12} md={6} lg={12}>
                                <Paper className={fixedHeightPaper}>
                                    <Typography variant="h5" component="h2" className={classes.header} style={{textAlign: 'center', marginBottom: 10}}>
                                        Comparativo
                                    </Typography>
                                    <Compare />
                                </Paper>
                              </Grid>                                                
                            );
                          }

                        })()}
                        
                        {/* Sales by Time */}
                        <Grid item xs={12} md={12} lg={12}>
                        <Paper className={fixedHeightPaper}>
                            <SalesTime />
                        </Paper>
                        </Grid>
                    </Grid>
                    </Container>
                    <Copyright />
                </main>
                </div>
                </ThemeProvider>
            );
            }

            // Render principal
            return (
                
                <RenderDashboard />
            ); 
        }
}

export default withRouter(connect(null, actions)(Dashboard));