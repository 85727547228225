
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'SALES_TOTALS':

            let data_sales_totals = action.payload_sales_totals;
            let venta_sales_totals = 0;

            copy.data_sales_totals = data_sales_totals;

            data_sales_totals.forEach(function(element){
              venta_sales_totals = venta_sales_totals + element.accounts[0][0].cuentas_totales
            })

            copy.sales_totals=venta_sales_totals;

          return copy
        
        default:
            return state;
      }
  }