import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import 'moment/locale/es';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateSelector from './DateSelector';
import * as actions from '../../actions';


const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      
    },
    input: {
      display: 'none',
    },
  }),
);


class FilterButtonsMobile extends Component {

    componentDidMount() {
      }

      


    render() {
        const RenderFilterButtonsMobile = () => {
            const classes = useStyles();
            const [open, setOpen] = React.useState(false);
          
            const [startDate, setStartDate] = React.useState(false);
            const [endDate, setEndDate] = React.useState(false);
          
            const [filter, setFilter] = React.useState((localStorage.getItem('filterDate') !== null) ? localStorage.getItem('filterDate') : 'ano');
          
            function setDateRangeFilter(){
              switch (filter) {
                  case 'hoy':
                      setStartDate(moment().format('YYYY-MM-DD'));
                      setEndDate(moment().format('YYYY-MM-DD'));
                      break;
          
                  case 'ayer':
                      setStartDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
                      setEndDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
                      break;
          
                  case 'semana':
                      setStartDate(moment().startOf('week').format('YYYY-MM-DD'));
                      setEndDate(moment().endOf('week').format('YYYY-MM-DD'));
                      break;
          
                  case 'mes':
                      setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
                      setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
                      break;
          
                  case 'ano':
                      setStartDate(moment().startOf('year').format('YYYY-MM-DD'));
                      setEndDate(moment().endOf('year').format('YYYY-MM-DD'));
                      break;
                  case 'periodo':
                      
                      break;
                  default:
                      setStartDate(moment().format('YYYY-MM-DD'));
                      setEndDate(moment().format('YYYY-MM-DD'));
                }
            }
          
            function setDatesFinals(){
                if ((startDate) && (endDate)){
                    localStorage.setItem('startDate', startDate);
                    localStorage.setItem('endDate', endDate);
                }
            }
          
            function handleClickFilter(filterClick){
              setFilter(filterClick);
              localStorage.setItem('filterDate', filterClick);

              return true;
            }
          
            function handleClickOpen() {
              setOpen(true);
            }
          
            const handleClose = () => {
              setOpen(false);
              // setSelectedValue(value);
            };
          
            const SimpleDialog = () => {
              return (
                  <Dialog
                      open={open}
                  >
                      <DialogTitle id="simple-dialog-title">Seleccione el rango de fechas</DialogTitle>
                      <DateSelector />
          
                      <Button variant="contained" className={classes.button} color='primary'
                          onClick={() => {
                              // Filtrar las gráficas al día de ayer
                              handleClose();
                              this.props.dispatch(actions.get_reports('periodo'));
                          }}      
                      >
                          Buscar
                      </Button>
          
                  </Dialog>
              )
            }
          
            React.useEffect(setDateRangeFilter, [filter]);
          
            React.useEffect(setDatesFinals, [startDate, endDate]);
          
            return (
              <Grid container direction="column" justify="center" alignItems="center">
                      <Button data-key={1} variant="contained" className={classes.button} color={(filter === 'hoy') ? 'primary' : 'default'}
                          onClick={() => {
                              // Filtrar las gráficas al día de hoy
                              handleClickFilter('hoy');
                              this.props.dispatch(actions.get_reports('hoy'));
                          }}
                      >
                          Hoy
                      </Button>
                      <Button data-key={2} variant="contained" className={classes.button} color={(filter === 'ayer') ? 'primary' : 'default'}
                          onClick={() => {
                              // Filtrar las gráficas al día de ayer
                              handleClickFilter('ayer');
                              this.props.dispatch(actions.get_reports('ayer'));
                          }}      
                      >
                          Ayer
                      </Button>
                      <Button data-key={3} variant="contained" className={classes.button} color={(filter === 'semana') ? 'primary' : 'default'}
                          onClick={() => {
                              // Filtrar las gráficas de la semana
                              handleClickFilter('semana');
                              this.props.dispatch(actions.get_reports('semana'));
                          }}      
                      >
                          Semana
                      </Button>
                      <Button data-key={4} variant="contained" className={classes.button} color={(filter === 'mes') ? 'primary' : 'default'}
                          onClick={() => {
                              // Filtrar las gráficas del mes
                              handleClickFilter('mes');
                              this.props.dispatch(actions.get_reports('mes'));
                          }}      
                      >
                          Mes
                      </Button>
                      <Button data-key={5} variant="contained" className={classes.button} color={(filter === 'ano') ? 'primary' : 'default'}
                          onClick={() => {
                              // Filtrar las gráficas del año
                              handleClickFilter('ano');
                              this.props.dispatch(actions.get_reports('ano'));
                          }}      
                      >
                          Año
                      </Button>
                      <Button data-key={6} variant="contained" className={classes.button} color={(filter === 'periodo') ? 'primary' : 'default'}
                          onClick={() => {
                              // Filtrar las gráficas con rango de fechas personalizado
                              handleClickFilter('periodo');
                              handleClickOpen();
                          }}      
                      >
                          Período
                      </Button>
                      <SimpleDialog open={open} onClose={handleClose} />
                      
              </Grid>
            );
          };

        return (
            <Hidden mdUp>
                <RenderFilterButtonsMobile />
            </Hidden>
            );
    }
}

function mapStateToProps(state) {
    return {state}
}
  
export default connect(mapStateToProps)(FilterButtonsMobile);