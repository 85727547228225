import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={2}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    widthTab: {
        minWidth: '50%'
    }
  }));

class CancelSales extends Component {

    componentDidMount() { 
    }
  
    render() {
        const {cancelSales, cancelQTY} = this.props;

        let options = {
            chart: {
                type: 'bar'
            },
            title: {
                text: 'DESCUENTOS/CORTESÍAS/CANCELACIONES/ANULACIONES'
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Totales en pesos - $MXN'
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        }
        
        let optionsQTY = {
            chart: {
                type: 'bar'
            },
            title: {
                text: 'DESCUENTOS/CORTESÍAS/CANCELACIONES/ANULACIONES'
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Cantidad de cuentas'
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        }
        if(cancelSales){
            if(cancelSales.cancel_sales) {
                let cancel_sales = cancelSales.cancel_sales;

                var categories = [];
                var data_cancel = [];

                cancel_sales.forEach(function(element){
                    categories.push(element.name);
                })
                cancel_sales.forEach(function(element){
                    data_cancel.push(element.y);
                })

                options = {
                    chart: {
                        type: 'bar'
                    },
                    title: {
                        text: 'DESCUENTOS/CORTESÍAS/CANCELACIONES/ANULACIONES'
                    },
                    xAxis: {
                        categories: categories
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Totales en pesos - $MXN'
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            stacking: 'normal'
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total por tipo de operación',
                        data: data_cancel
                    }]
                }
            }
        }

        if(cancelQTY){
            if(cancelQTY.cancel_qty) {
                let cancel_qty = cancelQTY.cancel_qty;

                var categoriesQTY = [];
                var data_cancel_qty = [];

                cancel_qty.forEach(function(element){
                    categoriesQTY.push(element.name);
                })
                cancel_qty.forEach(function(element){
                    data_cancel_qty.push(element.y);
                })

                optionsQTY = {
                    chart: {
                        type: 'bar'
                    },
                    title: {
                        text: 'DESCUENTOS/CORTESÍAS/CANCELACIONES/ANULACIONES'
                    },
                    xAxis: {
                        categories: categoriesQTY
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Cantidad de cuentas'
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            stacking: 'normal'
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Cuentas',
                        data: data_cancel_qty
                    }]
                }
            }
        }

        const RenderCancelSales = () => {
            const classes = useStyles();
            const [value, setValue] = React.useState(0);
            const handleChange = (event, newValue) => {
                setValue(newValue);
            };
            return (
                <div style={{ textAlign: 'center' }}>
                    <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab className={classes.widthTab} label="Ventas" {...a11yProps(0)} />
                        <Tab className={classes.widthTab} label="Cuentas" {...a11yProps(1)} />
                    </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                    </TabPanel>


                    <TabPanel value={value} index={1}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsQTY}
                    />
                    </TabPanel>
                </div>
            );
        };

        // Render principal
        return (
            <RenderCancelSales />
        );  
    }

}

function mapStateToProps(state) {
    return {
        cancelSales: state.reports_cancel_sales,
        cancelQTY: state.reports_cancel_qty
    }
}

export default connect(mapStateToProps)(CancelSales);