import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


export default function MaterialUIPickers() {
  // The first commit of Material-UI
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());

  function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
  }

  function handleStartDateChange(date) {
    setSelectedStartDate(date);
    localStorage.setItem('startDate', formatDate(date));
  }

  function handleEndDateChange(date) {
    setSelectedEndDate(date);
    localStorage.setItem('endDate', formatDate(date));
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container direction="column" justify="center" alignItems="center">
      <KeyboardDatePicker
          color="inherit"
          margin="normal"
          id="date-picker-dialog"
          label="Seleccione fecha inicial"
          format="dd/MM/yyyy"
          value={selectedStartDate}
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            />
        <KeyboardDatePicker
          color="inherit"
          margin="normal"
          id="date-picker-dialog"
          label="Seleccione fecha final"
          format="dd/MM/yyyy"
          value={selectedEndDate}
          onChange={handleEndDateChange}
          KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            />
      </Grid>
      
    </MuiPickersUtilsProvider>
  );
}