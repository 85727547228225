
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'SALES_HOUR':

            const sales_hour = action.payload_sales_hour;
            copy.sales_hour = JSON.parse(JSON.stringify(sales_hour));

          return copy
        
        default:
            return state;
      }
  }