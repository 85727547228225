import {QueryBuilderComponent} from '@syncfusion/ej2-react-querybuilder';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import React, { Component } from 'react';

// import moment from 'moment';
import 'moment/locale/es';

import { DataManager, Query } from '@syncfusion/ej2-data';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page } from '@syncfusion/ej2-react-grids';

import * as actions from '../../actions';
import { connect } from 'react-redux';


const hardwareData = []

class Reports extends Component {

  constructor(props) {
    super(props);
    this.updateRule = this.updateRule.bind(this);
    this.updateRuleButton = this.updateRuleButton.bind(this);
    this.onGridCreated = this.onGridCreated.bind(this)
    this.datamanager = new DataManager(hardwareData);
    this.query = new Query().select(['sucursal', 'orden', 'mesa', 'persona', 'total', 'fecha']);
    this.columnData = [
        {field: 'sucursal', label: 'Sucursal', type: 'string'},
        { field: 'orden', label: 'Orden', type: 'string' },
        { field: 'mesa', label: 'Mesa', type: 'string' },
        { field: 'persona', label: 'Comensales', type: 'number' },
        { field: 'total', label: 'Total', type: 'number' },
        { field: 'fecha', label: 'Fecha', type: 'date', format: 'dd/MM/yyyy'}
    ];

    this.importRules = {
      'condition': 'or',
      'rules': []
    };
  }
    updateRule(args) {
          let predicate = this.qbObj.getPredicate(args.rule);

          if (isNullOrUndefined(predicate)) {
              this.gridObj.query = new Query().select(['sucursal', 'orden', 'mesa', 'persona', 'total', 'fecha']);
          }
          else {
              this.gridObj.query = new Query().select(['sucursal', 'orden', 'mesa', 'persona', 'total', 'fecha'])
                  .where(predicate);
          }
          this.gridObj.refresh();
      }

      updateRuleButton() {
          let predicate = this.qbObj.getPredicate({ condition: this.qbObj.rule.condition, rules: this.qbObj.rule.rules });

          console.log("Predicate: ", predicate);

          if (isNullOrUndefined(predicate)) {
              this.gridObj.query = new Query().select(['sucursal', 'orden', 'mesa', 'persona', 'total', 'fecha']);
          }
          else {
              this.gridObj.query = new Query().select(['sucursal', 'orden', 'mesa', 'persona', 'total', 'fecha'])
                  .where(predicate);
          }
          this.gridObj.refresh();
      }
      onGridCreated() {
          this.updateRule({ rule: this.qbObj.getValidRules(this.qbObj.rule) });
      }

  componentDidMount() {
  }

  render() {

    const {facturaSales} = this.props;

    if(facturaSales){
        facturaSales.factura_sales.forEach(function(element){
            element.data[0].forEach(function(value){
                hardwareData.push({
                sucursal: element.sucursal,
                orden: value.ORDEN,
                mesa: value.MESA,
                persona: value.PERSONA,
                total: value.TOT,
                fecha: new Date(value.FECHA)
                })
            })
        })
    }

    return (<div className='control-pane'>
              <div className='control-section qb-section'>
                  <div className='row'>
                      <div className='col-lg-12 control-section qb-section'>
                          <QueryBuilderComponent width='100%' dataSource={hardwareData} columns={this.columnData} rule={this.importRules} ref={(scope) => { this.qbObj = scope; }} />
                          <ButtonComponent onClick={this.updateRuleButton} >Aplicar Filtro</ButtonComponent>
                          {/* <QueryBuilderComponent width='100%' dataSource={hardwareData} columns={this.columnData} rule={this.importRules} ruleChange={this.updateRule.bind(this)} ref={(scope) => { this.qbObj = scope; }}>
                          </QueryBuilderComponent> */}
                      </div>
                      <div className='col-lg-12 control-section qb-section'>
                          <div className='content-wrapper'>
                              <GridComponent allowPaging={true} dataSource={this.datamanager} width='100%' ref={(scope) => { this.gridObj = scope; }} query={this.query} created={this.onGridCreated}>
                                  <ColumnsDirective>
                                      <ColumnDirective field='sucursal' headerText='Sucursal' width='140' textAlign='Left'/>
                                      <ColumnDirective field='orden' headerText='Orden' width='60' textAlign='Left'/>
                                      <ColumnDirective field='mesa' headerText='Mesa' width='60' textAlign='Left'/>
                                      <ColumnDirective field='persona' headerText='Comensales' width='100'/>
                                      <ColumnDirective field='total' headerText='Total' width='160'/>
                                      <ColumnDirective field='fecha' headerText='Fecha' type="date" width='120' format='dd/MM/yyyy'/>
                                  </ColumnsDirective>
                                  <Inject services={[Page]}/>
                              </GridComponent>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          );
  }
};


function mapStateToProps(state) {
    return {
        facturaSales: state.reports_factura_sales
    }
}

export default connect(mapStateToProps, actions)(Reports);