
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'CLIENTS_DAY':

            const clients_day = action.payload_clients_day;
            copy.clients_day = JSON.parse(JSON.stringify(clients_day));

          return copy
        
        default:
            return state;
      }
  }