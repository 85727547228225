
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'AREA_SALES':

            const area_sales = action.payload_area_sales;
            copy.area_sales=JSON.parse(JSON.stringify(area_sales));

          return copy
        
        default:
            return state;
      }
  }