import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';

// import { getAuthToken } from '../api/LocalStorageApi';

const API_URL = 'http://157.245.13.64:3333/api/';
// const API_URL = 'http://127.0.0.1:3333/api/';

const startLogin = () => {
    return {
      type: 'LOGIN_STARTED'
    };
  };

export const login = (email, password) => async dispatch => {

    dispatch(startLogin());
    return await axios.post(`${API_URL}login`, {
        email,
        password
    })
    .then(response => {
        localStorage.setItem('token_prod', response.data.token);
        if (response.data.platform_id === 2){
          localStorage.setItem('platform', "Restpro");
        }else{
          localStorage.setItem('platform', "Restbar");
        }
        setTimeout(function(){ 
          dispatch({ type: 'LOGIN_SUCCESS', payload_login: response.data });
        }, 2000);
        
    })
    .catch(response => {
        dispatch({ type: 'LOGIN_ERROR', payload_login: response.data });
    });    
};

export const get_reports = (filter) => async dispatch => {
    const req = axios.create({
        headers: {Authorization: `Bearer ${localStorage.getItem('token_prod')}`}
    });
    switch (filter) {
      case 'hoy':
        let startDate = moment().format('YYYY-MM-DD');
        let endDate = moment().format('YYYY-MM-DD');

        // Compare
        let endDate_hoy_compare = moment().format('YYYY-MM-DD');

        let startDate_hoy_previous_year = moment().subtract(1, 'year').format('YYYY-MM-DD');
        let endDate_hoy_previous_year = moment().subtract(1, 'year').format('YYYY-MM-DD');

        let startDate_hoy_previous = moment().subtract(1, 'days').format('YYYY-MM-DD');
        let endDate_hoy_previous = moment().subtract(1, 'days').format('YYYY-MM-DD');

        let data = {
          start_date: startDate,
          end_date: endDate
        }

        let data_hoy_compare = {
          start_date: startDate,
          end_date: endDate_hoy_compare,
          type: filter,
          start_date_vs: startDate_hoy_previous,
          end_date_vs: endDate_hoy_previous
        }

        let data_hoy_compare_year = {
          start_date: startDate,
          end_date: endDate_hoy_compare,
          type: filter,
          start_date_vs: startDate_hoy_previous_year,
          end_date_vs: endDate_hoy_previous_year
        }

        req.post(`${API_URL}top-worst-foods`, data)
        .then(response => {
          dispatch({ type: 'TOP_WORST_FOODS', payload_food: response.data });
        })

        req.post(`${API_URL}top-worst-drinks`, data)
        .then(response => {
          dispatch({ type: 'TOP_WORST_DRINKS', payload_drink: response.data });
        })

        req.post(`${API_URL}top-worst-three`, data)
        .then(response => {
          dispatch({ type: 'TOP_WORST_THREE', payload_three: response.data });
        })

        req.post(`${API_URL}top-worst-four`, data)
        .then(response => {
          dispatch({ type: 'TOP_WORST_FOUR', payload_four: response.data });
        })

        req.post(`${API_URL}total-sales`, data)
        .then(response => {
            dispatch({ type: 'TOTAL_SALES', payload_total_sales: response.data });
        })

        
        req.post(`${API_URL}qty-accounts`, data)
        .then(response => {
          dispatch({ type: 'SALES_TOTALS', payload_sales_totals: response.data });
        })

        
        req.post(`${API_URL}qty-clients`, data)
        .then(response => {
          dispatch({ type: 'TOTAL_CLIENTS', payload_total_clients: response.data });
        })
               

        req.post(`${API_URL}sales-cost`, data)
        .then(response => {
          dispatch({ type: 'SALES_COST', payload_sales_cost: response.data });
        })
        
        req.post(`${API_URL}sales-catego`, data)
        .then(response => {
          dispatch({ type: 'SALES_CATEGO', payload_sales_catego: response.data });
        })
        
        req.post(`${API_URL}sales-waiter`, data)
        .then(response => {
          dispatch({ type: 'SALES_WAITER', payload_waiter: response.data });
        })
        
        req.post(`${API_URL}accounts-waiter`, data)
        .then(response => {
          dispatch({ type: 'ACCOUNTS_WAITER', payload_accounts_waiter: response.data });
        })
        
        req.post(`${API_URL}clients-waiter`, data)
        .then(response => {
          dispatch({ type: 'CLIENTS_WAITER', payload_clients_waiter: response.data });
        })
        
        req.post(`${API_URL}sales-payment-method`, data)
        .then(response => {
          dispatch({ type: 'SALES_PAYMENT_METHOD', payload_payment: response.data });
        })
        
        req.post(`${API_URL}area-sales`, data)
        .then(response => {
          dispatch({ type: 'AREA_SALES', payload_area_sales: response.data });
        })
        
        req.post(`${API_URL}accounts-area`, data)
        .then(response => {
          dispatch({ type: 'ACCOUNTS_AREA', payload_accounts_area: response.data });
        })
        
        req.post(`${API_URL}clients-area`, data)
        .then(response => {
          dispatch({ type: 'CLIENTS_AREA', payload_clients_area: response.data });
        })
        
        req.post(`${API_URL}type-sales`, data)
        .then(response => {
          dispatch({ type: 'TYPE_SALES', payload_type_sales: response.data });
        })

        req.post(`${API_URL}cancel-sales`, data)
        .then(response => {
          dispatch({ type: 'CANCEL_SALES', payload_cancel_sales: response.data });
        })
        
        req.post(`${API_URL}cancel-qty`, data)
        .then(response => {
          dispatch({ type: 'CANCEL_QTY', payload_cancel_qty: response.data });
        })

        req.post(`${API_URL}sales-hour`, data)
        .then(response => {
          dispatch({ type: 'SALES_HOUR', payload_sales_hour: response.data });
        })
        
        req.post(`${API_URL}accounts-hour`, data)
        .then(response => {
          dispatch({ type: 'ACCOUNTS_HOUR', payload_accounts_hour: response.data });
        })
        
        req.post(`${API_URL}clients-hour`, data)
        .then(response => {
          dispatch({ type: 'CLIENTS_HOUR', payload_clients_hour: response.data });
        })

        req.post(`${API_URL}sales-day`, data)
        .then(response => {
          dispatch({ type: 'SALES_DAY', payload_sales_day: response.data });
        })
        
        req.post(`${API_URL}accounts-day`, data)
        .then(response => {
          dispatch({ type: 'ACCOUNTS_DAY', payload_accounts_day: response.data });
        })
        
        req.post(`${API_URL}clients-day`, data)
        .then(response => {
          dispatch({ type: 'CLIENTS_DAY', payload_clients_day: response.data });
        })

        if (localStorage.getItem('platform') === "Restbar"){
            req.post(`${API_URL}compare-previous-period`, data_hoy_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD', payload_compare_previous_period: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-accounts`, data_hoy_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_ACCOUNTS', payload_compare_previous_period_accounts: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-clients`, data_hoy_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_CLIENTS', payload_compare_previous_period_clients: response.data });
            })

            req.post(`${API_URL}compare-previous-period-year`, data_hoy_compare_year)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR', payload_compare_previous_period_year: response.data });
            })

            req.post(`${API_URL}compare-previous-period-year-accounts`, data_hoy_compare_year)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR_ACCOUNTS', payload_compare_previous_period_year_accounts: response.data });
            })

            req.post(`${API_URL}compare-previous-period-year-clients`, data_hoy_compare_year)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR_CLIENTS', payload_compare_previous_period_year_clients: response.data });
            })

        }

        
        
        break;

      case 'ayer':
          // setStartDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
          // setEndDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
          let startDate_ayer = moment().subtract(1, 'days').format('YYYY-MM-DD');
          let endDate_ayer = moment().subtract(1, 'days').format('YYYY-MM-DD');

          // Compare
          let endDate_ayer_compare = moment().subtract(1, 'days').format('YYYY-MM-DD');

          let startDate_ayer_previous_year = moment().subtract(1, 'days').subtract(1, 'year').format('YYYY-MM-DD');
          let endDate_ayer_previous_year = moment().subtract(1, 'days').subtract(1, 'year').format('YYYY-MM-DD');

          let startDate_ayer_previous = moment().subtract(2, 'days').format('YYYY-MM-DD');
          let endDate_ayer_previous = moment().subtract(2, 'days').format('YYYY-MM-DD');
  
          let data_ayer = {
            start_date: startDate_ayer,
            end_date: endDate_ayer
          }

          let data_ayer_compare = {
            start_date: startDate_ayer,
            end_date: endDate_ayer_compare,
            type: filter,
            start_date_vs: startDate_ayer_previous,
            end_date_vs: endDate_ayer_previous
          }

          let data_ayer_compare_year = {
            start_date: startDate_ayer,
            end_date: endDate_ayer_compare,
            type: filter,
            start_date_vs: startDate_ayer_previous_year,
            end_date_vs: endDate_ayer_previous_year
          }
  
          req.post(`${API_URL}top-worst-foods`, data_ayer)
          .then(response => {
            dispatch({ type: 'TOP_WORST_FOODS', payload_food: response.data });
          })
  
          req.post(`${API_URL}top-worst-drinks`, data_ayer)
          .then(response => {
            dispatch({ type: 'TOP_WORST_DRINKS', payload_drink: response.data });
          })

          req.post(`${API_URL}top-worst-three`, data_ayer)
          .then(response => {
            dispatch({ type: 'TOP_WORST_THREE', payload_three: response.data });
          })

          req.post(`${API_URL}top-worst-four`, data_ayer)
          .then(response => {
            dispatch({ type: 'TOP_WORST_FOUR', payload_four: response.data });
          })
  
          req.post(`${API_URL}total-sales`, data_ayer)
          .then(response => {
              dispatch({ type: 'TOTAL_SALES', payload_total_sales: response.data });
          })
  
          
          req.post(`${API_URL}qty-accounts`, data_ayer)
          .then(response => {
            dispatch({ type: 'SALES_TOTALS', payload_sales_totals: response.data });
          })
  
          
          req.post(`${API_URL}qty-clients`, data_ayer)
          .then(response => {
            dispatch({ type: 'TOTAL_CLIENTS', payload_total_clients: response.data });
          })
                 
  
          req.post(`${API_URL}sales-cost`, data_ayer)
          .then(response => {
            dispatch({ type: 'SALES_COST', payload_sales_cost: response.data });
          })
          
          req.post(`${API_URL}sales-catego`, data_ayer)
          .then(response => {
            dispatch({ type: 'SALES_CATEGO', payload_sales_catego: response.data });
          })
          
          req.post(`${API_URL}sales-waiter`, data_ayer)
          .then(response => {
            dispatch({ type: 'SALES_WAITER', payload_waiter: response.data });
          })

          req.post(`${API_URL}accounts-waiter`, data_ayer)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_WAITER', payload_accounts_waiter: response.data });
          })
          
          req.post(`${API_URL}clients-waiter`, data_ayer)
          .then(response => {
            dispatch({ type: 'CLIENTS_WAITER', payload_clients_waiter: response.data });
          })
          
          req.post(`${API_URL}sales-payment-method`, data_ayer)
          .then(response => {
            dispatch({ type: 'SALES_PAYMENT_METHOD', payload_payment: response.data });
          })
          
          req.post(`${API_URL}area-sales`, data_ayer)
          .then(response => {
            dispatch({ type: 'AREA_SALES', payload_area_sales: response.data });
          })

          req.post(`${API_URL}accounts-area`, data_ayer)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_AREA', payload_accounts_area: response.data });
          })
          
          req.post(`${API_URL}clients-area`, data_ayer)
          .then(response => {
            dispatch({ type: 'CLIENTS_AREA', payload_clients_area: response.data });
          })
          
          req.post(`${API_URL}type-sales`, data_ayer)
          .then(response => {
            dispatch({ type: 'TYPE_SALES', payload_type_sales: response.data });
          })
  
          req.post(`${API_URL}cancel-sales`, data_ayer)
          .then(response => {
            dispatch({ type: 'CANCEL_SALES', payload_cancel_sales: response.data });
          })

          req.post(`${API_URL}cancel-qty`, data_ayer)
          .then(response => {
            dispatch({ type: 'CANCEL_QTY', payload_cancel_qty: response.data });
          })

          req.post(`${API_URL}sales-hour`, data_ayer)
          .then(response => {
            dispatch({ type: 'SALES_HOUR', payload_sales_hour: response.data });
          })

          req.post(`${API_URL}accounts-hour`, data_ayer)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_HOUR', payload_accounts_hour: response.data });
          })
          
          req.post(`${API_URL}clients-hour`, data_ayer)
          .then(response => {
            dispatch({ type: 'CLIENTS_HOUR', payload_clients_hour: response.data });
          })

          req.post(`${API_URL}sales-day`, data_ayer)
          .then(response => {
            dispatch({ type: 'SALES_DAY', payload_sales_day: response.data });
          })

          req.post(`${API_URL}accounts-day`, data_ayer)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_DAY', payload_accounts_day: response.data });
          })
          
          req.post(`${API_URL}clients-day`, data_ayer)
          .then(response => {
            dispatch({ type: 'CLIENTS_DAY', payload_clients_day: response.data });
          })

          if (localStorage.getItem('platform') === "Restbar"){

            
            req.post(`${API_URL}compare-previous-period`, data_ayer_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD', payload_compare_previous_period: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-accounts`, data_ayer_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_ACCOUNTS', payload_compare_previous_period_accounts: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-clients`, data_ayer_compare)
          .then(response => {
            dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_CLIENTS', payload_compare_previous_period_clients: response.data });
          })
          
          req.post(`${API_URL}compare-previous-period-year`, data_ayer_compare_year)
          .then(response => {
            dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR', payload_compare_previous_period_year: response.data });
          })
          
          req.post(`${API_URL}compare-previous-period-year-accounts`, data_ayer_compare_year)
          .then(response => {
            dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR_ACCOUNTS', payload_compare_previous_period_year_accounts: response.data });
          })
          
          req.post(`${API_URL}compare-previous-period-year-clients`, data_ayer_compare_year)
          .then(response => {
            dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR_CLIENTS', payload_compare_previous_period_year_clients: response.data });
          })
          
          }

          break;

      case 'semana':
          let startDate_semana = moment().startOf('week').format('YYYY-MM-DD');
          let endDate_semana = moment().endOf('week').format('YYYY-MM-DD');

          // Compare
          let endDate_semana_compare = moment().format('YYYY-MM-DD');

          let startDate_semana_previous_year = moment().startOf('week').subtract(1, 'year').format('YYYY-MM-DD');
          let endDate_semana_previous_year = moment().subtract(1, 'year').format('YYYY-MM-DD');

          let startDate_semana_previous = moment().startOf('week').subtract(1, 'week').format('YYYY-MM-DD');
          let endDate_semana_previous = moment().subtract(1, 'week').format('YYYY-MM-DD');

  
          let data_semana = {
            start_date: startDate_semana,
            end_date: endDate_semana
          }

          let data_semana_compare = {
            start_date: startDate_semana,
            end_date: endDate_semana_compare,
            type: filter,
            start_date_vs: startDate_semana_previous,
            end_date_vs: endDate_semana_previous
          }

          let data_semana_compare_year = {
            start_date: startDate_semana,
            end_date: endDate_semana_compare,
            type: filter,
            start_date_vs: startDate_semana_previous_year,
            end_date_vs: endDate_semana_previous_year
          }
  
          req.post(`${API_URL}top-worst-foods`, data_semana)
          .then(response => {
            dispatch({ type: 'TOP_WORST_FOODS', payload_food: response.data });
          })
  
          req.post(`${API_URL}top-worst-drinks`, data_semana)
          .then(response => {
            dispatch({ type: 'TOP_WORST_DRINKS', payload_drink: response.data });
          })

          req.post(`${API_URL}top-worst-three`, data_semana)
          .then(response => {
            dispatch({ type: 'TOP_WORST_THREE', payload_three: response.data });
          })

          req.post(`${API_URL}top-worst-four`, data_semana)
          .then(response => {
            dispatch({ type: 'TOP_WORST_FOUR', payload_four: response.data });
          })
  
          req.post(`${API_URL}total-sales`, data_semana)
          .then(response => {
              dispatch({ type: 'TOTAL_SALES', payload_total_sales: response.data });
          })
  
          
          req.post(`${API_URL}qty-accounts`, data_semana)
          .then(response => {
            dispatch({ type: 'SALES_TOTALS', payload_sales_totals: response.data });
          })
  
          
          req.post(`${API_URL}qty-clients`, data_semana)
          .then(response => {
            dispatch({ type: 'TOTAL_CLIENTS', payload_total_clients: response.data });
          })
                 
  
          req.post(`${API_URL}sales-cost`, data_semana)
          .then(response => {
            dispatch({ type: 'SALES_COST', payload_sales_cost: response.data });
          })
          
          req.post(`${API_URL}sales-catego`, data_semana)
          .then(response => {
            dispatch({ type: 'SALES_CATEGO', payload_sales_catego: response.data });
          })
          
          req.post(`${API_URL}sales-waiter`, data_semana)
          .then(response => {
            dispatch({ type: 'SALES_WAITER', payload_waiter: response.data });
          })

          req.post(`${API_URL}accounts-waiter`, data_semana)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_WAITER', payload_accounts_waiter: response.data });
          })
          
          req.post(`${API_URL}clients-waiter`, data_semana)
          .then(response => {
            dispatch({ type: 'CLIENTS_WAITER', payload_clients_waiter: response.data });
          })
          
          req.post(`${API_URL}sales-payment-method`, data_semana)
          .then(response => {
            dispatch({ type: 'SALES_PAYMENT_METHOD', payload_payment: response.data });
          })
          
          req.post(`${API_URL}area-sales`, data_semana)
          .then(response => {
            dispatch({ type: 'AREA_SALES', payload_area_sales: response.data });
          })

          req.post(`${API_URL}accounts-area`, data_semana)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_AREA', payload_accounts_area: response.data });
          })
          
          req.post(`${API_URL}clients-area`, data_semana)
          .then(response => {
            dispatch({ type: 'CLIENTS_AREA', payload_clients_area: response.data });
          })
          
          req.post(`${API_URL}type-sales`, data_semana)
          .then(response => {
            dispatch({ type: 'TYPE_SALES', payload_type_sales: response.data });
          })
  
          req.post(`${API_URL}cancel-sales`, data_semana)
          .then(response => {
            dispatch({ type: 'CANCEL_SALES', payload_cancel_sales: response.data });
          })

          req.post(`${API_URL}cancel-qty`, data_semana)
          .then(response => {
            dispatch({ type: 'CANCEL_QTY', payload_cancel_qty: response.data });
          })

          if (localStorage.getItem('platform') === "Restbar"){

            
            req.post(`${API_URL}compare-previous-period`, data_semana_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD', payload_compare_previous_period: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-accounts`, data_semana_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_ACCOUNTS', payload_compare_previous_period_accounts: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-clients`, data_semana_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_CLIENTS', payload_compare_previous_period_clients: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-year`, data_semana_compare_year)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR', payload_compare_previous_period_year: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-year-accounts`, data_semana_compare_year)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR_ACCOUNTS', payload_compare_previous_period_year_accounts: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-year-clients`, data_semana_compare_year)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR_CLIENTS', payload_compare_previous_period_year_clients: response.data });
            })
            
          }

          req.post(`${API_URL}sales-hour`, data_semana)
          .then(response => {
            dispatch({ type: 'SALES_HOUR', payload_sales_hour: response.data });
          })

          req.post(`${API_URL}accounts-hour`, data_semana)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_HOUR', payload_accounts_hour: response.data });
          })
          
          req.post(`${API_URL}clients-hour`, data_semana)
          .then(response => {
            dispatch({ type: 'CLIENTS_HOUR', payload_clients_hour: response.data });
          })

          req.post(`${API_URL}sales-day`, data_semana)
          .then(response => {
            dispatch({ type: 'SALES_DAY', payload_sales_day: response.data });
          })

          req.post(`${API_URL}accounts-day`, data_semana)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_DAY', payload_accounts_day: response.data });
          })
          
          req.post(`${API_URL}clients-day`, data_semana)
          .then(response => {
            dispatch({ type: 'CLIENTS_DAY', payload_clients_day: response.data });
          })
          
          break;

      case 'mes':
          let startDate_mes = moment().startOf('month').format('YYYY-MM-DD');
          let endDate_mes = moment().endOf('month').format('YYYY-MM-DD');

          // Compare
          let endDate_mes_compare = moment().format('YYYY-MM-DD');

          let startDate_mes_previous_year = moment().startOf('month').subtract(1, 'year').format('YYYY-MM-DD');
          let endDate_mes_previous_year = moment().subtract(1, 'year').format('YYYY-MM-DD');

          let startDate_mes_previous = moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD');
          let endDate_mes_previous = moment().subtract(1, 'month').format('YYYY-MM-DD');
  
          let data_mes = {
            start_date: startDate_mes,
            end_date: endDate_mes
          }

          let data_mes_compare = {
            start_date: startDate_mes,
            end_date: endDate_mes_compare,
            type: filter,
            start_date_vs: startDate_mes_previous,
            end_date_vs: endDate_mes_previous
          }

          let data_mes_compare_year = {
            start_date: startDate_mes,
            end_date: endDate_mes_compare,
            type: filter,
            start_date_vs: startDate_mes_previous_year,
            end_date_vs: endDate_mes_previous_year
          }
  
          req.post(`${API_URL}top-worst-foods`, data_mes)
          .then(response => {
            dispatch({ type: 'TOP_WORST_FOODS', payload_food: response.data });
          })
  
          req.post(`${API_URL}top-worst-drinks`, data_mes)
          .then(response => {
            dispatch({ type: 'TOP_WORST_DRINKS', payload_drink: response.data });
          })

          req.post(`${API_URL}top-worst-three`, data_mes)
          .then(response => {
            dispatch({ type: 'TOP_WORST_THREE', payload_three: response.data });
          })

          req.post(`${API_URL}top-worst-four`, data_mes)
          .then(response => {
            dispatch({ type: 'TOP_WORST_FOUR', payload_four: response.data });
          })
  
          req.post(`${API_URL}total-sales`, data_mes)
          .then(response => {
              dispatch({ type: 'TOTAL_SALES', payload_total_sales: response.data });
          })
  
          
          req.post(`${API_URL}qty-accounts`, data_mes)
          .then(response => {
            dispatch({ type: 'SALES_TOTALS', payload_sales_totals: response.data });
          })
  
          
          req.post(`${API_URL}qty-clients`, data_mes)
          .then(response => {
            dispatch({ type: 'TOTAL_CLIENTS', payload_total_clients: response.data });
          })
                 
  
          req.post(`${API_URL}sales-cost`, data_mes)
          .then(response => {
            dispatch({ type: 'SALES_COST', payload_sales_cost: response.data });
          })
          
          req.post(`${API_URL}sales-catego`, data_mes)
          .then(response => {
            dispatch({ type: 'SALES_CATEGO', payload_sales_catego: response.data });
          })
          
          req.post(`${API_URL}sales-waiter`, data_mes)
          .then(response => {
            dispatch({ type: 'SALES_WAITER', payload_waiter: response.data });
          })

          req.post(`${API_URL}accounts-waiter`, data_mes)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_WAITER', payload_accounts_waiter: response.data });
          })
          
          req.post(`${API_URL}clients-waiter`, data_mes)
          .then(response => {
            dispatch({ type: 'CLIENTS_WAITER', payload_clients_waiter: response.data });
          })
          
          req.post(`${API_URL}sales-payment-method`, data_mes)
          .then(response => {
            dispatch({ type: 'SALES_PAYMENT_METHOD', payload_payment: response.data });
          })
          
          req.post(`${API_URL}area-sales`, data_mes)
          .then(response => {
            dispatch({ type: 'AREA_SALES', payload_area_sales: response.data });
          })

          req.post(`${API_URL}accounts-area`, data_mes)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_AREA', payload_accounts_area: response.data });
          })
          
          req.post(`${API_URL}clients-area`, data_mes)
          .then(response => {
            dispatch({ type: 'CLIENTS_AREA', payload_clients_area: response.data });
          })
          
          req.post(`${API_URL}type-sales`, data_mes)
          .then(response => {
            dispatch({ type: 'TYPE_SALES', payload_type_sales: response.data });
          })
  
          req.post(`${API_URL}cancel-sales`, data_mes)
          .then(response => {
            dispatch({ type: 'CANCEL_SALES', payload_cancel_sales: response.data });
          })

          req.post(`${API_URL}cancel-qty`, data_mes)
          .then(response => {
            dispatch({ type: 'CANCEL_QTY', payload_cancel_qty: response.data });
          })

          req.post(`${API_URL}sales-hour`, data_mes)
          .then(response => {
            dispatch({ type: 'SALES_HOUR', payload_sales_hour: response.data });
          })

          req.post(`${API_URL}accounts-hour`, data_mes)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_HOUR', payload_accounts_hour: response.data });
          })
          
          req.post(`${API_URL}clients-hour`, data_mes)
          .then(response => {
            dispatch({ type: 'CLIENTS_HOUR', payload_clients_hour: response.data });
          })

          req.post(`${API_URL}sales-day`, data_mes)
          .then(response => {
            dispatch({ type: 'SALES_DAY', payload_sales_day: response.data });
          })

          req.post(`${API_URL}accounts-day`, data_mes)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_DAY', payload_accounts_day: response.data });
          })
          
          req.post(`${API_URL}clients-day`, data_mes)
          .then(response => {
            dispatch({ type: 'CLIENTS_DAY', payload_clients_day: response.data });
          })
          
          req.post(`${API_URL}factura-sales`, data_mes)
          .then(response => {
            dispatch({ type: 'FACTURA_SALES', payload_factura_sales: response.data });
          })

          if (localStorage.getItem('platform') === "Restbar"){


            
            req.post(`${API_URL}compare-previous-period`, data_mes_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD', payload_compare_previous_period: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-accounts`, data_mes_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_ACCOUNTS', payload_compare_previous_period_accounts: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-clients`, data_mes_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_CLIENTS', payload_compare_previous_period_clients: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-year`, data_mes_compare_year)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR', payload_compare_previous_period_year: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-year-accounts`, data_mes_compare_year)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR_ACCOUNTS', payload_compare_previous_period_year_accounts: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-year-clients`, data_mes_compare_year)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR_CLIENTS', payload_compare_previous_period_year_clients: response.data });
            })
            
          }

          break;

      case 'ano':
          let startDate_ano = moment().startOf('year').format('YYYY-MM-DD');
          let endDate_ano = moment().format('YYYY-MM-DD');

          // Compare
          let endDate_ano_compare = moment().format('YYYY-MM-DD');

          let startDate_ano_previous_period = moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
          let endDate_ano_previous_period = moment().subtract(1, 'year').format('YYYY-MM-DD');

          let startDate_ano_previous_year = moment().startOf('year').subtract(2, 'year').format('YYYY-MM-DD');
          let endDate_ano_previous_year = moment().subtract(2, 'year').format('YYYY-MM-DD');

          let data_ano_compare = {
            start_date: startDate_ano,
            end_date: endDate_ano_compare,
            type: filter,
            start_date_vs: startDate_ano_previous_period,
            end_date_vs: endDate_ano_previous_period
          }

          let data_ano_compare_year = {
            start_date: startDate_ano,
            end_date: endDate_ano_compare,
            type: filter,
            start_date_vs: startDate_ano_previous_year,
            end_date_vs: endDate_ano_previous_year
          }
  
          let data_ano = {
            start_date: startDate_ano,
            end_date: endDate_ano
          }
  
          req.post(`${API_URL}top-worst-foods`, data_ano)
          .then(response => {
            dispatch({ type: 'TOP_WORST_FOODS', payload_food: response.data });
          })
  
          req.post(`${API_URL}top-worst-drinks`, data_ano)
          .then(response => {
            dispatch({ type: 'TOP_WORST_DRINKS', payload_drink: response.data });
          })

          req.post(`${API_URL}top-worst-three`, data_ano)
          .then(response => {
            dispatch({ type: 'TOP_WORST_THREE', payload_three: response.data });
          })

          req.post(`${API_URL}top-worst-four`, data_ano)
          .then(response => {
            dispatch({ type: 'TOP_WORST_FOUR', payload_four: response.data });
          })
  
          req.post(`${API_URL}total-sales`, data_ano)
          .then(response => {
              dispatch({ type: 'TOTAL_SALES', payload_total_sales: response.data });
          })
  
          
          req.post(`${API_URL}qty-accounts`, data_ano)
          .then(response => {
            dispatch({ type: 'SALES_TOTALS', payload_sales_totals: response.data });
          })
  
          
          req.post(`${API_URL}qty-clients`, data_ano)
          .then(response => {
            dispatch({ type: 'TOTAL_CLIENTS', payload_total_clients: response.data });
          })
                 
  
          req.post(`${API_URL}sales-cost`, data_ano)
          .then(response => {
            dispatch({ type: 'SALES_COST', payload_sales_cost: response.data });
          })
          
          req.post(`${API_URL}sales-catego`, data_ano)
          .then(response => {
            dispatch({ type: 'SALES_CATEGO', payload_sales_catego: response.data });
          })
          
          req.post(`${API_URL}sales-waiter`, data_ano)
          .then(response => {
            dispatch({ type: 'SALES_WAITER', payload_waiter: response.data });
          })

          req.post(`${API_URL}accounts-waiter`, data_ano)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_WAITER', payload_accounts_waiter: response.data });
          })
          
          req.post(`${API_URL}clients-waiter`, data_ano)
          .then(response => {
            dispatch({ type: 'CLIENTS_WAITER', payload_clients_waiter: response.data });
          })
          
          req.post(`${API_URL}sales-payment-method`, data_ano)
          .then(response => {
            dispatch({ type: 'SALES_PAYMENT_METHOD', payload_payment: response.data });
          })
          
          req.post(`${API_URL}area-sales`, data_ano)
          .then(response => {
            dispatch({ type: 'AREA_SALES', payload_area_sales: response.data });
          })

          req.post(`${API_URL}service-type-sales`, data_ano)
          .then(response => {
            dispatch({ type: 'SERVICE_TYPE_SALES', payload_service_type_sales: response.data });
          })

          req.post(`${API_URL}accounts-area`, data_ano)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_AREA', payload_accounts_area: response.data });
          })
          
          req.post(`${API_URL}clients-area`, data_ano)
          .then(response => {
            dispatch({ type: 'CLIENTS_AREA', payload_clients_area: response.data });
          })
          
          req.post(`${API_URL}type-sales`, data_ano)
          .then(response => {
            dispatch({ type: 'TYPE_SALES', payload_type_sales: response.data });
          })
  
          req.post(`${API_URL}cancel-sales`, data_ano)
          .then(response => {
            dispatch({ type: 'CANCEL_SALES', payload_cancel_sales: response.data });
          })

          req.post(`${API_URL}cancel-qty`, data_ano)
          .then(response => {
            dispatch({ type: 'CANCEL_QTY', payload_cancel_qty: response.data });
          })

          if (localStorage.getItem('platform') === "Restbar"){
              req.post(`${API_URL}compare-previous-period`, data_ano_compare)
              .then(response => {
                dispatch({ type: 'COMPARE_PREVIOUS_PERIOD', payload_compare_previous_period: response.data });
              })

              req.post(`${API_URL}compare-previous-period-accounts`, data_ano_compare)
              .then(response => {
                dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_ACCOUNTS', payload_compare_previous_period_accounts: response.data });
              })
              
              req.post(`${API_URL}compare-previous-period-clients`, data_ano_compare)
              .then(response => {
                dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_CLIENTS', payload_compare_previous_period_clients: response.data });
              })

              req.post(`${API_URL}compare-previous-period-year`, data_ano_compare_year)
              .then(response => {
                dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR', payload_compare_previous_period_year: response.data });
              })

              req.post(`${API_URL}compare-previous-period-year-accounts`, data_ano_compare_year)
              .then(response => {
                dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR_ACCOUNTS', payload_compare_previous_period_year_accounts: response.data });
              })

              req.post(`${API_URL}compare-previous-period-year-clients`, data_ano_compare_year)
              .then(response => {
                dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR_CLIENTS', payload_compare_previous_period_year_clients: response.data });
              })
          }

          

          req.post(`${API_URL}sales-hour`, data_ano)
          .then(response => {
            dispatch({ type: 'SALES_HOUR', payload_sales_hour: response.data });
          })

          req.post(`${API_URL}accounts-hour`, data_ano)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_HOUR', payload_accounts_hour: response.data });
          })
          
          req.post(`${API_URL}clients-hour`, data_ano)
          .then(response => {
            dispatch({ type: 'CLIENTS_HOUR', payload_clients_hour: response.data });
          })

          req.post(`${API_URL}sales-day`, data_ano)
          .then(response => {
            dispatch({ type: 'SALES_DAY', payload_sales_day: response.data });
          })

          req.post(`${API_URL}accounts-day`, data_ano)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_DAY', payload_accounts_day: response.data });
          })
          
          req.post(`${API_URL}clients-day`, data_ano)
          .then(response => {
            dispatch({ type: 'CLIENTS_DAY', payload_clients_day: response.data });
          })
          
          break;
      case 'periodo':
          let startDate_periodo = localStorage.getItem('startDate');
          let endDate_periodo = localStorage.getItem('endDate');

          // Compare

          let startDate_periodo_previous_period = moment(startDate_periodo).subtract(1, 'year').format('YYYY-MM-DD');
          let endDate_periodo_previous_period = moment(endDate_periodo).subtract(1, 'year').format('YYYY-MM-DD');

          let startDate_periodo_previous_year = moment(startDate_periodo).subtract(2, 'year').format('YYYY-MM-DD');
          let endDate_periodo_previous_year = moment(endDate_periodo).subtract(2, 'year').format('YYYY-MM-DD');
  
          let data_periodo = {
            start_date: startDate_periodo,
            end_date: endDate_periodo
          }

          let data_periodo_compare = {
            start_date: startDate_periodo,
            end_date: endDate_periodo,
            type: filter,
            start_date_vs: startDate_periodo_previous_period,
            end_date_vs: endDate_periodo_previous_period
          }

          let data_periodo_compare_year = {
            start_date: startDate_periodo,
            end_date: endDate_periodo,
            type: filter,
            start_date_vs: startDate_periodo_previous_year,
            end_date_vs: endDate_periodo_previous_year
          }
  
          req.post(`${API_URL}top-worst-foods`, data_periodo)
          .then(response => {
            dispatch({ type: 'TOP_WORST_FOODS', payload_food: response.data });
          })
  
          req.post(`${API_URL}top-worst-drinks`, data_periodo)
          .then(response => {
            dispatch({ type: 'TOP_WORST_DRINKS', payload_drink: response.data });
          })

          req.post(`${API_URL}top-worst-three`, data_periodo)
          .then(response => {
            dispatch({ type: 'TOP_WORST_THREE', payload_three: response.data });
          })

          req.post(`${API_URL}top-worst-four`, data_periodo)
          .then(response => {
            dispatch({ type: 'TOP_WORST_FOUR', payload_four: response.data });
          })
  
          req.post(`${API_URL}total-sales`, data_periodo)
          .then(response => {
              dispatch({ type: 'TOTAL_SALES', payload_total_sales: response.data });
          })
  
          
          req.post(`${API_URL}qty-accounts`, data_periodo)
          .then(response => {
            dispatch({ type: 'SALES_TOTALS', payload_sales_totals: response.data });
          })
  
          
          req.post(`${API_URL}qty-clients`, data_periodo)
          .then(response => {
            dispatch({ type: 'TOTAL_CLIENTS', payload_total_clients: response.data });
          })
                 
  
          req.post(`${API_URL}sales-cost`, data_periodo)
          .then(response => {
            dispatch({ type: 'SALES_COST', payload_sales_cost: response.data });
          })
          
          req.post(`${API_URL}sales-catego`, data_periodo)
          .then(response => {
            dispatch({ type: 'SALES_CATEGO', payload_sales_catego: response.data });
          })
          
          req.post(`${API_URL}sales-waiter`, data_periodo)
          .then(response => {
            dispatch({ type: 'SALES_WAITER', payload_waiter: response.data });
          })

          req.post(`${API_URL}accounts-waiter`, data_periodo)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_WAITER', payload_accounts_waiter: response.data });
          })
          
          req.post(`${API_URL}clients-waiter`, data_periodo)
          .then(response => {
            dispatch({ type: 'CLIENTS_WAITER', payload_clients_waiter: response.data });
          })
          
          req.post(`${API_URL}sales-payment-method`, data_periodo)
          .then(response => {
            dispatch({ type: 'SALES_PAYMENT_METHOD', payload_payment: response.data });
          })
          
          req.post(`${API_URL}area-sales`, data_periodo)
          .then(response => {
            dispatch({ type: 'AREA_SALES', payload_area_sales: response.data });
          })

          req.post(`${API_URL}accounts-area`, data_periodo)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_AREA', payload_accounts_area: response.data });
          })
          
          req.post(`${API_URL}clients-area`, data_periodo)
          .then(response => {
            dispatch({ type: 'CLIENTS_AREA', payload_clients_area: response.data });
          })
          
          req.post(`${API_URL}type-sales`, data_periodo)
          .then(response => {
            dispatch({ type: 'TYPE_SALES', payload_type_sales: response.data });
          })
  
          req.post(`${API_URL}cancel-sales`, data_periodo)
          .then(response => {
            dispatch({ type: 'CANCEL_SALES', payload_cancel_sales: response.data });
          })

          req.post(`${API_URL}cancel-qty`, data_periodo)
          .then(response => {
            dispatch({ type: 'CANCEL_QTY', payload_cancel_qty: response.data });
          })

          req.post(`${API_URL}sales-hour`, data_periodo)
          .then(response => {
            dispatch({ type: 'SALES_HOUR', payload_sales_hour: response.data });
          })

          req.post(`${API_URL}accounts-hour`, data_periodo)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_HOUR', payload_accounts_hour: response.data });
          })
          
          req.post(`${API_URL}clients-hour`, data_periodo)
          .then(response => {
            dispatch({ type: 'CLIENTS_HOUR', payload_clients_hour: response.data });
          })

          req.post(`${API_URL}sales-day`, data_periodo)
          .then(response => {
            dispatch({ type: 'SALES_DAY', payload_sales_day: response.data });
          })

          req.post(`${API_URL}accounts-day`, data_periodo)
          .then(response => {
            dispatch({ type: 'ACCOUNTS_DAY', payload_accounts_day: response.data });
          })
          
          req.post(`${API_URL}clients-day`, data_periodo)
          .then(response => {
            dispatch({ type: 'CLIENTS_DAY', payload_clients_day: response.data });
          })

          if (localStorage.getItem('platform') === "Restbar"){

            
            req.post(`${API_URL}compare-previous-period`, data_periodo_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD', payload_compare_previous_period: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-accounts`, data_periodo_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_ACCOUNTS', payload_compare_previous_period_accounts: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-clients`, data_periodo_compare)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_CLIENTS', payload_compare_previous_period_clients: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-year`, data_periodo_compare_year)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR', payload_compare_previous_period_year: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-year-accounts`, data_periodo_compare_year)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR_ACCOUNTS', payload_compare_previous_period_year_accounts: response.data });
            })
            
            req.post(`${API_URL}compare-previous-period-year-clients`, data_periodo_compare_year)
            .then(response => {
              dispatch({ type: 'COMPARE_PREVIOUS_PERIOD_YEAR_CLIENTS', payload_compare_previous_period_year_clients: response.data });
            })
            
          }

          break;
      default:
          
    }

  };