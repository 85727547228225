export default function(state = null, action) {
    
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'LOGIN_SUCCESS':

            const login = action.payload_login;
            copy.login=JSON.parse(JSON.stringify(login));
            copy.error = false

          return copy
        
        case 'LOGIN_ERROR':

            
            copy.error = true;

          return copy
        default:
        return state;
    }
  }