import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    widthTab: {
        minWidth: '33%'
    }
  }));


class SalesDay extends Component {

    componentDidMount() { 
    }
  
    render() {

        const day_of_week = [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
        ]

        const {salesDay, accountsDay, clientsDay} = this.props;

        let options = {
            chart: {
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            title: {
                text: 'Ventas por día'
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            },
            credits: {
                enabled: false
            }
        }
        
        let optionsAccounts = {
            chart: {
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            title: {
                text: 'Cuentas por día'
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            },
            credits: {
                enabled: false
            }
        }
        
        let optionsClients = {
            chart: {
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            title: {
                text: 'Clientes por día'
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            },
            credits: {
                enabled: false
            }
        }
        if(salesDay){
            if(salesDay.sales_day) {
                let sales_day = JSON.parse(JSON.stringify(salesDay.sales_day));
                const dataObject= sales_day.map((data) => {
                    return data.data[0];
                });

                const aggregatedDataObject = [];
                const data_sales_day = [];

                dataObject.forEach(function(location){
                    location.forEach(function(element){
                        aggregatedDataObject.push(element)
                    })
                })

                let results = aggregatedDataObject.reduce(function(res, obj) {
                    if (!(obj.dia in res))
                        res.__array.push(res[obj.dia] = obj);
                    else {
                        res[obj.dia].totales += obj.totales;
                    }
                    return res;
                }, {__array:[]}).__array;

                results.forEach(function(element){
                    data_sales_day.push({
                        name: day_of_week[element.dia-1],
                        y: parseFloat(Number(element.totales).toFixed(2))
                    })
                })


                options = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        animation: false
                    },
                    title: {
                        text: 'Ventas por día'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_sales_day
                    }]
                }
            }
        }

        if(accountsDay){
            if(accountsDay.accounts_day) {
                let accounts_day = JSON.parse(JSON.stringify(accountsDay.accounts_day));
                const dataObjectAccounts= accounts_day.map((data) => {
                    return data.data[0];
                });

                const aggregatedDataObjectAccounts = [];
                const data_accounts_day = [];

                dataObjectAccounts.forEach(function(location){
                    location.forEach(function(element){
                        aggregatedDataObjectAccounts.push(element)
                    })
                })

                let resultsAccounts = aggregatedDataObjectAccounts.reduce(function(res, obj) {
                    if (!(obj.dia in res))
                        res.__array.push(res[obj.dia] = obj);
                    else {
                        res[obj.dia].totales += obj.totales;
                    }
                    return res;
                }, {__array:[]}).__array;

                resultsAccounts.forEach(function(element){
                    data_accounts_day.push({
                        name: day_of_week[element.dia-1],
                        y: parseFloat(Number(element.totales).toFixed(2))
                    })
                })


                optionsAccounts = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        animation: false
                    },
                    title: {
                        text: 'Cuentas por día'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_accounts_day
                    }]
                }
            }
        }

        if(clientsDay){
            if(clientsDay.clients_day) {
                let clients_day = JSON.parse(JSON.stringify(clientsDay.clients_day));
                const dataObjectClients= clients_day.map((data) => {
                    return data.data[0];
                });

                const aggregatedDataObjectClients = [];
                const data_clients_day = [];

                dataObjectClients.forEach(function(location){
                    location.forEach(function(element){
                        aggregatedDataObjectClients.push(element)
                    })
                })

                let resultsClients = aggregatedDataObjectClients.reduce(function(res, obj) {
                    if (!(obj.dia in res))
                        res.__array.push(res[obj.dia] = obj);
                    else {
                        res[obj.dia].totales += obj.totales;
                    }
                    return res;
                }, {__array:[]}).__array;

                resultsClients.forEach(function(element){
                    data_clients_day.push({
                        name: day_of_week[element.dia-1],
                        y: parseFloat(Number(element.totales).toFixed(2))
                    })
                })


                optionsClients = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        animation: false
                    },
                    title: {
                        text: 'Clientes por día'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_clients_day
                    }]
                }
            }
        }

        const RenderSalesDay = () => {
            const classes = useStyles();
            const [value, setValue] = React.useState(0);
            const handleChange = (event, newValue) => {
                setValue(newValue);
            };
            return (
                <div style={{ textAlign: 'center' }}>
                    <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab className={classes.widthTab} label="Ventas" {...a11yProps(0)} />
                        <Tab className={classes.widthTab} label="Cuentas" {...a11yProps(1)} />
                        <Tab className={classes.widthTab} label="Comensales" {...a11yProps(2)} />
                    </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsAccounts}
                    />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsClients}
                    />
                    </TabPanel>
                </div>
            );
        };

        return (
            <RenderSalesDay />
        ); 
    }
}


function mapStateToProps(state) {
    return {
        salesDay: state.reports_sales_day,
        accountsDay: state.reports_accounts_day,
        clientsDay: state.reports_clients_day
    }
}

export default connect(mapStateToProps)(SalesDay);