
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'ACCOUNTS_DAY':

            const accounts_day = action.payload_accounts_day;
            copy.accounts_day = JSON.parse(JSON.stringify(accounts_day));

          return copy
        
        default:
            return state;
      }
  }