
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'TOP_WORST_THREE':

            copy.top_three = undefined;
            copy.worst_three = undefined;
            const top_three = action.payload_three;
            copy.data_three = JSON.parse(JSON.stringify(top_three));
            
            var dataObject_three= top_three.map((data) => {
              return data.data[0];
            })
            
            

            let dataObject_three_worst= top_three.map((data) => {
              return data.data_worst[0];
              })
              
            let aggregatedDataObject_three = []
            let aggregatedDataObject_three_worst = []
            
            dataObject_three.forEach(function(location){
                location.forEach(function(element){
                    aggregatedDataObject_three.push(element)
                })
            })

            dataObject_three_worst.forEach(function(location){
                location.forEach(function(element){
                    aggregatedDataObject_three_worst.push(element)
                })
            })

            
            
            let results_food = aggregatedDataObject_three.reduce(function(res, obj) {
                if (!(obj.codigo in res))
                    res.__array.push(res[obj.codigo] = obj);
                else {
                    res[obj.codigo].cantidad += obj.cantidad;
                    res[obj.codigo].total_facturado += obj.total_facturado;
                }
                return res;
            }, {__array:[]}).__array
                            .sort(function(a,b) { return b.total_facturado - a.total_facturado; });

            let results_three_worst = aggregatedDataObject_three_worst.reduce(function(res, obj) {
                if (!(obj.codigo in res))
                    res.__array.push(res[obj.codigo] = obj);
                else {
                    res[obj.codigo].cantidad += obj.cantidad;
                    res[obj.codigo].total_facturado += obj.total_facturado;
                }
                return res;
            }, {__array:[]}).__array
                            .sort(function(a,b) { return a.total_facturado - b.total_facturado; });

            copy.top_three=results_food;
            copy.worst_three=results_three_worst;

          return copy
        
        default:
            return state;
      }
  }