
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'CLIENTS_AREA':

            const clients_area = action.payload_clients_area;
            copy.clients_area=JSON.parse(JSON.stringify(clients_area));

          return copy
        
        default:
            return state;
      }
  }