import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  AppBar,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: '100%',
    maxHeight: 155
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


function currencyFormat(num) {
  return (num === undefined) ? 0 : num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class TopWorstFoods extends Component {

  componentDidMount() {
    
  }

  render() {
    const {top_worst_food} = this.props;

    var codigo = 0;
    var descripcion = "";
    var total_facturado = "";
    var type = "";
    
    let data_top = false;
    if(top_worst_food){
      if(top_worst_food.top_food){

        data_top = true;
        
      }
    }

    let data_worst = false;
    if(top_worst_food){
      if(top_worst_food.worst_food){

        data_worst = true;
        
      }

    }

    const RenderTopWorstFoods = () => {

      const classes = useStyles();
      const [value, setValue] = React.useState(0);
      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      const [open, setOpen] = React.useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };

      const handleClickValue = (newCodigo, newDescripcion, newTotal_facturado, newType) => {
        codigo = newCodigo;
        descripcion = newDescripcion;
        total_facturado = newTotal_facturado;
        type = newType;
      };
    
      return (
        <Card style={{height: '100%', minHeight: 435, overflow: 'auto'}}
          className={clsx(classes.root)}
        >
          <CardHeader
            title="TOP / WORST de Alimentos"
          />
          <Divider />
          <AppBar position="static">
          <Tabs value={value} onChange={handleChange} variant="fullWidth" centered aria-label="simple tabs example">
            <Tab label="Top" {...a11yProps(0)} />
            <Tab label="Worst" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
        <CardContent className={classes.content}>
              <div className={classes.inner}>
                <Table>
                  <TableHead style={{whiteSpace: 'nowrap'}}>
                    <TableRow>
                      <TableCell>Descripción</TableCell>
                      <TableCell>($) Total Venta</TableCell>
                    </TableRow>
                  </TableHead>

                  {(() => {
                    if(data_top){
                      return(
                        <TableBody>
                        {top_worst_food.top_food.map(result => (
                          <TableRow
                            hover
                            key={result.codigo}
                          >
                            <TableCell onClick={() => {
                              handleClickValue(result.codigo, result.descripcion, result.total_facturado, "top");
                              handleClickOpen();
                            }}>{result.descripcion}</TableCell>
                            <TableCell>{currencyFormat( Number(result.total_facturado) )}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>                     
                      );
                    }

                  })()}

                </Table>
              </div>
          </CardContent>
        </TabPanel>
        <TabPanel value={value} index={1}>
        <CardContent className={classes.content}>
              <div className={classes.inner}>
                <Table>
                  <TableHead style={{whiteSpace: 'nowrap'}}>
                    <TableRow>
                      <TableCell>Descripción</TableCell>
                      <TableCell>($) Total Venta</TableCell>
                    </TableRow>
                  </TableHead>

                  {(() => {
                    if(data_worst){
                      return(
                        <TableBody>
                        {top_worst_food.worst_food.map(result => (
                          <TableRow
                            hover
                            key={result.codigo}
                          >
                            <TableCell onClick={() => {
                              handleClickValue(result.codigo, result.descripcion, result.total_facturado, "worst");
                              handleClickOpen();
                            }}>{result.descripcion}</TableCell>
                            <TableCell>{currencyFormat( Number(result.total_facturado) )}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>                     
                      );
                    }

                  })()}

                </Table>
              </div>
          </CardContent>
        </TabPanel>
          
          <Divider />
          <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
              <DialogTitle id="customized-dialog-title" >
                {`Detalle por Sucursal de ${descripcion}`} 
              </DialogTitle>
              <DialogContent dividers>
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Sucursal</TableCell>
                        <TableCell align="left">Venta</TableCell>
                        <TableCell align="left">%</TableCell>
                      </TableRow>
                    </TableHead>
                    {(() => {
                      if(top_worst_food){
                        if(top_worst_food.worst_food){
                          var data_filtrada = [];
                          if(type === "top"){
                            top_worst_food.data_food.forEach(function(element){
                              element.data[0].forEach(function(inside){
                                if(codigo === inside.codigo){
                                  data_filtrada.push({'sucursal': element.sucursal, 'data': inside})
                                }
                              })
                            })
                          }
                          if(type === "worst"){
                            top_worst_food.data_food.forEach(function(element){
                              element.data_worst[0].forEach(function(inside){
                                if(codigo === inside.codigo){
                                  data_filtrada.push({'sucursal': element.sucursal, 'data': inside})
                                }
                              })
                            })
                          }
                          return(
                            <TableBody>
                            {data_filtrada.map((result, i) => (
                              <TableRow
                                hover
                                key={i}
                              >
                                <TableCell>{result.sucursal}</TableCell>
                                <TableCell>{currencyFormat( Number(result.data.total_facturado) )}</TableCell>
                                <TableCell>{currencyFormat( Number(result.data.total_facturado * 100 / total_facturado) )}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>                 
                          );
                        }
                      }

                    })()}
                  </Table>
                </Paper>
              </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cerrar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Card>
      );
    };

    return (
        <RenderTopWorstFoods />
    );

  }
}

function mapStateToProps(state) {
  return {
    top_worst_food: state.reports_top_worst_food
  }
}
export default connect(mapStateToProps)(TopWorstFoods);