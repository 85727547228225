import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
 

class PaymentMethod extends Component {

    componentDidMount() { 
    }
  
    render() {

        const {payment_method} = this.props;

        let options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                animation: false
            },
            title: {
                text: 'Formas de pago'
            },
            plotOptions: {
                animation: false,
                series: {
                    animation: {
                        duration: 0
                    }
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            credits: {
                enabled: false
            }
        }
        if(payment_method){
            if(payment_method.sales_payment_method) {
                let sales_payment_method = JSON.parse(JSON.stringify(payment_method.sales_payment_method));                

                const dataObject= sales_payment_method.map((data) => {
                    return data.data[0];
                });
                const data_sales_payment_method = [];

                const aggregatedDataObject = [];
                

                dataObject.forEach(function(location){
                    location.forEach(function(element){
                        aggregatedDataObject.push(element)
                    })
                })

                if (sales_payment_method[0].platform === "Restpro"){

                    // console.log("Data: ", aggregatedDataObject);
                    let results_payment = aggregatedDataObject.reduce(function(res, obj) {
                        if (!(obj.Tipo in res))
                            res.__array.push(res[obj.Tipo] = obj);
                        else {
                            res[obj.Tipo].total += obj.total;
                        }
                        return res;
                    }, {__array:[]}).__array
                                    .sort(function(a,b) { return b.total - a.total; });

                    results_payment.forEach(function(element){
                        data_sales_payment_method.push({
                            name: String(element.Tipo),
                            y: parseFloat(Number(element.total).toFixed(2))
                        })

                    })

                    

                }else if(sales_payment_method[0].platform === "Restbar"){
                    data_sales_payment_method.push({
                        name: 'Efectivo',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.efectivo).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_sales_payment_method.push({
                        name: 'Tarjetas',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.tarjeta).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_sales_payment_method.push({
                        name: 'Vales',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.vales).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_sales_payment_method.push({
                        name: 'Crédito',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.credito).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_sales_payment_method.push({
                        name: 'Otros',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.otros).toFixed(2)) || 0);
                          }, 0) || 0
                    });
                }

                

                options = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        animation: false
                    },
                    title: {
                        text: 'Formas de pago'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        animation: false,
                        series: {
                            animation: {
                                duration: 0
                            }
                        },
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_sales_payment_method
                    }]
                }
            }
        }

        const RenderPaymentMethod = () => {
            return (
                <div style={{ textAlign: 'center' }}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
                </div>
            );
        };


        // Render principal
        return (
        
            <RenderPaymentMethod />
        );  
    }

}

function mapStateToProps(state) {
    return {
        payment_method: state.reports_payment
    }
}

export default connect(mapStateToProps)(PaymentMethod);