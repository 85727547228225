import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
 

class ServiceTypeSales extends Component {

    componentDidMount() { 
    }
  
    render() {

        const {service_type} = this.props;

        let options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                animation: false
            },
            title: {
                text: 'Ventas por Tipo de Servicio'
            },
            plotOptions: {
                animation: false,
                series: {
                    animation: {
                        duration: 0
                    }
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            credits: {
                enabled: false
            }
        }
        if(service_type){
            if(service_type.service_type_sales) {
                let service_type_sales = JSON.parse(JSON.stringify(service_type.service_type_sales));                

                const dataObject= service_type_sales.map((data) => {
                    return data.data[0];
                });
                const data_service_type_sales = [];

                const aggregatedDataObject = [];
                

                dataObject.forEach(function(location){
                    location.forEach(function(element){
                        aggregatedDataObject.push(element)
                    })
                })

                if (service_type_sales[0].platform === "Restpro"){


                    // console.log("Data del tipo de servicio: ", aggregatedDataObject);

                    data_service_type_sales.push({
                        name: 'Autoservicio',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_autoservicio).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_service_type_sales.push({
                        name: 'Ctas Consecutivas',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_cconsecutivas).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_service_type_sales.push({
                        name: 'Ctas Personalizadas',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_cpersonalizadas).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_service_type_sales.push({
                        name: 'Domicilio',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_domicilio).toFixed(2)) || 0);
                          }, 0) || 0
                    });
    
                    data_service_type_sales.push({
                        name: 'Eventos',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_eventos).toFixed(2)) || 0);
                          }, 0) || 0
                    });        
                    
                    data_service_type_sales.push({
                        name: 'Habitaciones',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_habitaciones).toFixed(2)) || 0);
                          }, 0) || 0
                    });        

                    data_service_type_sales.push({
                        name: 'Mesas',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_mmesas).toFixed(2)) || 0);
                          }, 0) || 0
                    });        

                    data_service_type_sales.push({
                        name: 'Venta Rápida',
                        y: aggregatedDataObject.reduce((acc, c) => {
                            return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.total_vrapida).toFixed(2)) || 0);
                          }, 0) || 0
                    });        

                }

                

                options = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        animation: false
                    },
                    title: {
                        text: 'Ventas por Tipo de Servicio'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        animation: false,
                        series: {
                            animation: {
                                duration: 0
                            }
                        },
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_service_type_sales
                    }]
                }
            }
        }

        const RenderServiceTypeSales = () => {
            return (
                <div style={{ textAlign: 'center' }}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
                </div>
            );
        };


        // Render principal
        return (
        
            <RenderServiceTypeSales />
        );  
    }

}

function mapStateToProps(state) {
    return {
        service_type: state.reports_service
    }
}

export default connect(mapStateToProps)(ServiceTypeSales);