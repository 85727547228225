import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import funnel from "highcharts/modules/funnel.js";

funnel(Highcharts);

class SalesType extends Component {

    componentDidMount() { 
    }
  
    render() {

        const {typeSales} = this.props;

        let options_type = {
            title: {
                text: 'Ventas por tipo'
            }
        }

        if(typeSales){
            if(typeSales.type_sales) {


                options_type = {
                    chart: {
                        type: 'pyramid'
                    },
                    title: {
                        text: 'Ventas por tipo',
                        x: -50
                    },
                    // tooltip: {
                    //     formatter: function() {
                    //         console.log(this);
                    //         return this.series.name;
                    //     }
                    // },
                    plotOptions: {
                        pyramid: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            showInLegend: true
                        },
                        series: {
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b> ({point.y:,.0f})',
                                color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                                softConnector: true
                            },
                            center: ['40%', '50%'],
                            width: '60%'
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Cantidad de ventas',
                        data: typeSales.type_sales
                    }]
                }
            }else{
                options_type = {
                    title: {
                        text: 'Ventas por tipo'
                    }
                }
            }
        }

        const RenderSalesType = () => {
            return (
                <div style={{ textAlign: 'center' }}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options_type}
                />
                </div>
            );
        };

        return (
        
            <RenderSalesType />
        );  
    }

}


function mapStateToProps(state) {
    return {
        typeSales: state.reports_type_sales
    }
}

export default connect(mapStateToProps)(SalesType);