
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'CANCEL_QTY':
            copy.cancel_qty = undefined;

            let cancel_qty = action.payload_cancel_qty;
            var dataObject_cancel= cancel_qty.map((data) => {
                return data.data[0];
            });

            var data_cancel_qty = [];

            let aggregatedDataObject_cancel_qty = [];

            dataObject_cancel.forEach(function(location){
                location.forEach(function(element){
                  aggregatedDataObject_cancel_qty.push(element)
                })
            })

            data_cancel_qty.push({
                name: 'Descuentos',
                y: aggregatedDataObject_cancel_qty.reduce((acc, c) => {
                    return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.cantidad_descuentos).toFixed(2)) || 0);
                  }, 0) || 0
            });
            data_cancel_qty.push({
                name: 'Cortesías',
                y: aggregatedDataObject_cancel_qty.reduce((acc, c) => {
                    return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.cantidad_cortesias).toFixed(2)) || 0);
                  }, 0) || 0
            });
            data_cancel_qty.push({
                name: 'Cancelaciones',
                y: aggregatedDataObject_cancel_qty.reduce((acc, c) => {
                    return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.cantidad_cancelaciones).toFixed(2)) || 0);
                  }, 0) || 0
            });
            data_cancel_qty.push({
                name: 'Anulaciones',
                y: aggregatedDataObject_cancel_qty.reduce((acc, c) => {
                    return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.cantidad_anulaciones).toFixed(2)) || 0);
                  }, 0) || 0
            });



            copy.cancel_qty=data_cancel_qty;

          return copy
        
        default:
            return state;
      }
  }