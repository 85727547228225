
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'TOP_WORST_FOUR':

            copy.top_four = undefined;
            copy.worst_four = undefined;
            const top_four = action.payload_four;
            copy.data_four = JSON.parse(JSON.stringify(top_four));
            
            var dataObject_four= top_four.map((data) => {
              return data.data[0];
            })
            
            

            let dataObject_four_worst= top_four.map((data) => {
              return data.data_worst[0];
              })
              
            let aggregatedDataObject_four = []
            let aggregatedDataObject_four_worst = []
            
            dataObject_four.forEach(function(location){
                location.forEach(function(element){
                    aggregatedDataObject_four.push(element)
                })
            })

            dataObject_four_worst.forEach(function(location){
                location.forEach(function(element){
                    aggregatedDataObject_four_worst.push(element)
                })
            })

            
            
            let results_food = aggregatedDataObject_four.reduce(function(res, obj) {
                if (!(obj.codigo in res))
                    res.__array.push(res[obj.codigo] = obj);
                else {
                    res[obj.codigo].cantidad += obj.cantidad;
                    res[obj.codigo].total_facturado += obj.total_facturado;
                }
                return res;
            }, {__array:[]}).__array
                            .sort(function(a,b) { return b.total_facturado - a.total_facturado; });

            let results_four_worst = aggregatedDataObject_four_worst.reduce(function(res, obj) {
                if (!(obj.codigo in res))
                    res.__array.push(res[obj.codigo] = obj);
                else {
                    res[obj.codigo].cantidad += obj.cantidad;
                    res[obj.codigo].total_facturado += obj.total_facturado;
                }
                return res;
            }, {__array:[]}).__array
                            .sort(function(a,b) { return a.total_facturado - b.total_facturado; });

            copy.top_four=results_food;
            copy.worst_four=results_four_worst;

          return copy
        
        default:
            return state;
      }
  }