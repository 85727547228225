import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={2}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    widthTab: {
        minWidth: '50%'
    }
  }));


class Categories extends Component {

    componentDidMount() { 
    }
  
    render() {
        const {catego} = this.props;

        let optionsSmall = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: 'Categorías por venta'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            credits: {
                enabled: false
            }
        }
        let optionsSmallQTY = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: 'Categorías por cantidad'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            credits: {
                enabled: false
            }
        }
        let options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            title: {
                text: 'Categorías por venta'
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false
                }
            },
            credits: {
                enabled: false
            }
        }

        let optionsQTY = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            title: {
                text: 'Categorías por cantidad'
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false
                }
            },
            credits: {
                enabled: false
            }
        }
        if(catego){
            if(catego.sales_catego) {
                let sales_catego = JSON.parse(JSON.stringify(catego.sales_catego));
                const dataObject= sales_catego.map((data) => {
                    return data.data[0];
                    });

                const aggregatedDataObject = [];
                const data_sales_catego = [];
                const data_sales_categoQTY = [];

                dataObject.forEach(function(location){
                    location.forEach(function(element){
                        aggregatedDataObject.push(element)
                    })
                });

                let results = aggregatedDataObject.reduce(function(res, obj) {
                    if (!(obj.cod_linea in res))
                        res.__array.push(res[obj.cod_linea] = obj);
                    else {
                        res[obj.cod_linea].cantidad += obj.cantidad;
                        res[obj.cod_linea].total_facturado += obj.total_facturado;
                    }
                    return res;
                }, {__array:[]}).__array
                                .sort(function(a,b) { return b.total_facturado - a.total_facturado; });

                results.forEach(function(element){
                    data_sales_catego.push({
                        name: element.descripcion,
                        y: parseFloat(Number(element.total_facturado).toFixed(2))
                    })
                });

                results.forEach(function(element){
                    data_sales_categoQTY.push({
                        name: element.descripcion,
                        y: parseInt(Number(element.cantidad))
                    })
                });


                optionsSmall = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    title: {
                        text: 'Categorías por venta'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_sales_catego
                    }]
                }

                optionsSmallQTY = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    title: {
                        text: 'Categorías por cantidad'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_sales_categoQTY.sort(function(a, b){return b.y - a.y})
                    }]
                }

                options = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                    },
                    title: {
                        text: 'Categorías por venta'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    legend:{
                        align: 'right',
                        verticalAlign:'middle',
                        width: 300,
                        itemWidth: 300
                    },
                    // exporting: {
                    //     buttons:{
                    //         contextButton:{
                    //             menuItems: [
                    //                 "printChart", 
                    //                 "separator", 
                    //                 "downloadPNG", 
                    //                 "downloadJPEG", 
                    //                 "downloadPDF", 
                    //                 "downloadSVG"
                    //             ]
                    //         }                            
                    //     },
                    //     showTable: false,  
                    // },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_sales_catego
                    }]
                }

                optionsQTY = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                    },
                    title: {
                        text: 'Categorías por cantidad'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    legend:{
                        align: 'right',
                        verticalAlign:'middle',
                        width: 300,
                        itemWidth: 300
                    },
                    // exporting: {
                    //     buttons:{
                    //         contextButton:{
                    //             menuItems: [
                    //                 "printChart", 
                    //                 "separator", 
                    //                 "downloadPNG", 
                    //                 "downloadJPEG", 
                    //                 "downloadPDF", 
                    //                 "downloadSVG"
                    //             ]
                    //         }                            
                    //     },
                    //     showTable: false,  
                    // },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_sales_categoQTY.sort(function(a, b){return b.y - a.y})
                    }]
                }
            }
        }

        const RenderCategories = () => {
            const classes = useStyles();
            const [value, setValue] = React.useState(0);
            const handleChange = (event, newValue) => {
                setValue(newValue);
            };

            return (
                <div style={{ textAlign: 'center' }}>
                    <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab className={classes.widthTab} label="Monto" {...a11yProps(0)} />
                        <Tab className={classes.widthTab} label="Cantidad" {...a11yProps(1)} />
                    </Tabs>
                    </AppBar>


                    <TabPanel value={value} index={0}>
                        <Hidden smDown>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                        </Hidden>
                        <Hidden mdUp>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsSmall}
                        />
                        </Hidden>
                    </TabPanel>


                    <TabPanel value={value} index={1}>
                        <Hidden smDown>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsQTY}
                        />
                        </Hidden>
                        <Hidden mdUp>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsSmallQTY}
                        />
                        </Hidden>
                    </TabPanel>
                
                </div>
            );
        };


        // Render principal
        return (
        
            <RenderCategories />
        );   
    }

};


function mapStateToProps(state) {
    return {
        catego: state.reports_catego
    }
}

export default connect(mapStateToProps)(Categories);