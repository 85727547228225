// SaleTotal
import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PageviewIcon from '@material-ui/icons/Pageview';
import Badge from '@material-ui/core/Badge';
import Paper from '@material-ui/core/Paper';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  icon: {
    height: 32,
    width: 32
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function currencyFormat(num) {
  return (num === undefined) ? 0 : num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function percentageCalculater(total, number) {
  return (Number(number * 100 / total).toFixed(0))
}


class SaleTotal extends Component {
  

  componentDidMount() { 
  }

  render() {
    // if(this.props.state.reports){
    //   if(this.props.state.reports.data_sale_total){
    //     console.log("Venta Total: ", this.props.state.reports.data_sale_total)
    //   }
    // }

    const {sales} = this.props;

    const RenderSaleTotal = () => {

      const classes = useStyles();
      const [open, setOpen] = React.useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
    
      return (
        <Card
          className={clsx(classes.root)}
        >
          <CardContent>
            <Grid
              container
              justify="space-between"
            >
              <Grid item>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                  noWrap
                >
                  Venta Total
                  {(() => {
                    if(sales){
                      if(sales.data_sale_total){
                        return(
                          <IconButton color="inherit">
                            <Badge badgeContent={0} color="secondary" onClick={handleClickOpen}>
                              <PageviewIcon />
                            </Badge>
                          </IconButton>              
                        );
                      }
                    }

                  })()}
                </Typography>
                <div>
                  <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title">
                      Detalle por Sucursal
                    </DialogTitle>
                    <DialogContent dividers>
                    <div className={classes.root}>
                      <Paper className={classes.paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Sucursal</TableCell>
                              <TableCell align="center">Venta</TableCell>
                              <TableCell align="center">%</TableCell>
                            </TableRow>
                          </TableHead>
                          {(() => {
                            if(sales){
                              if(sales.data_sale_total){
                                return(
                                  <TableBody>
                                  {sales.data_sale_total.map(result => (
                                    <TableRow
                                      hover
                                      key={result.sucursal}
                                    >
                                      <TableCell>{result.sucursal}</TableCell>
                                      <TableCell>{`$${currencyFormat( Number(result.sales[0][0].venta_total) )}`}</TableCell>
                                      <TableCell>{`${percentageCalculater(Number(sales.sale_total), Number(result.sales[0][0].venta_total) )}`}%</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>                 
                                );
                              }
                            }

                          })()}
                        </Table>
                      </Paper>
                    </div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cerrar
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                <Typography variant="h4">{(sales) ? (sales.sale_total) ? `$${currencyFormat(sales.sale_total)}`:'$' + 0 :'$' + 0}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    };

    // Render principal
    return (      
      <RenderSaleTotal />
    );

  }
}


function mapStateToProps(state) {
  return {
      sales: state.reports_sales
  }
}

export default connect(mapStateToProps)(SaleTotal);