import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PollIcon from '@material-ui/icons/Poll';
import BarChartIcon from '@material-ui/icons/BarChart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link } from "react-router-dom";

export const mainListItems = (
  <div>
    <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
    <ListItem button>
      <ListItemIcon>
        <MonetizationOnIcon />
      </ListItemIcon>
      <ListItemText primary="Ventas" />
    </ListItem>
    </Link>
    <Link to='/statistics' style={{ textDecoration: 'none', color: 'inherit' }}>
    <ListItem button>
      <ListItemIcon>
      <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Estadísticas" />
    </ListItem>
    </Link>
    {/* <Link to='/reports' style={{ textDecoration: 'none', color: 'inherit' }}>
    <ListItem button>
      <ListItemIcon>
        <PollIcon />
      </ListItemIcon>
      <ListItemText primary="Reportes" />
    </ListItem>
    </Link> */}
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);