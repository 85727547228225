import { combineReducers } from 'redux';
import authReducer from './authReducer';
import reportsReducer from './reportsReducers';
import reports_salesReducer from './reports_salesReducers';
import reports_average_accountReducer from './reports_average_accountReducers';
import reports_average_consumeReducer from './reports_average_consumeReducers';
import reports_top_worst_foodReducer from './reports_top_worst_foodReducers';

import reports_top_worst_threeReducer from './reports_top_worst_threeReducers';
import reports_top_worst_fourReducer from './reports_top_worst_fourReducers';


import reports_top_worst_drinkReducer from './reports_top_worst_drinkReducers';
import reports_categoReducer from './reports_categoReducers';
import reports_waiterReducer from './reports_waiterReducers';
import reports_accounts_waiterReducer from './reports_accounts_waiterReducers';
import reports_clients_waiterReducer from './reports_clients_waiterReducers';
import reports_paymentReducer from './reports_paymentReducers';
import reports_areaReducer from './reports_areaReducers';
import reports_serviceReducer from './reports_serviceReducers';
import reports_accounts_areaReducer from './reports_accounts_areaReducers';
import reports_clients_areaReducer from './reports_clients_areaReducers';
import reports_type_salesReducer from './reports_type_salesReducers';
import reports_cancel_salesReducer from './reports_cancel_salesReducers';
import reports_cancel_qtyReducer from './reports_cancel_qtyReducers';
import reports_sales_hourReducer from './reports_sales_hourReducers';
import reports_accounts_hourReducer from './reports_accounts_hourReducers';
import reports_clients_hourReducer from './reports_clients_hourReducers';
import reports_compareReducer from './reports_compareReducers';
import reports_compare_clientsReducer from './reports_compare_clientsReducers';
import reports_compare_accountsReducer from './reports_compare_accountsReducers';
import reports_sales_dayReducer from './reports_sales_dayReducers';
import reports_accounts_dayReducer from './reports_accounts_dayReducers';
import reports_clients_dayReducer from './reports_clients_dayReducers';
import reports_factura_salesReducer from './reports_factura_salesReducers';

import moment from 'moment';
import 'moment/locale/es';

// Inicializador
if (localStorage.getItem('filterDate') === null){
  console.log("Filter: ", localStorage.getItem('filterDate'));
  localStorage.setItem('startDate', moment().startOf('year').format('YYYY-MM-DD'));
  localStorage.setItem('endDate', moment().format('YYYY-MM-DD'));
  localStorage.setItem('filterDate', 'ano')
}

export default combineReducers({
  auth: authReducer,
  reports: reportsReducer,
  reports_sales: reports_salesReducer,
  reports_average_account: reports_average_accountReducer,
  reports_average_consume: reports_average_consumeReducer,
  reports_top_worst_food: reports_top_worst_foodReducer,
  reports_top_worst_three: reports_top_worst_threeReducer,
  reports_top_worst_four: reports_top_worst_fourReducer,
  reports_top_worst_drink: reports_top_worst_drinkReducer,
  reports_catego: reports_categoReducer,
  reports_waiter: reports_waiterReducer,
  reports_accounts_waiter: reports_accounts_waiterReducer,
  reports_clients_waiter: reports_clients_waiterReducer,
  reports_payment: reports_paymentReducer,
  reports_area: reports_areaReducer,
  reports_service: reports_serviceReducer,
  reports_type_sales: reports_type_salesReducer,
  reports_cancel_sales: reports_cancel_salesReducer,
  reports_cancel_qty: reports_cancel_qtyReducer,
  reports_sales_hour: reports_sales_hourReducer,
  reports_accounts_hour: reports_accounts_hourReducer,
  reports_clients_hour: reports_clients_hourReducer,
  reports_compare: reports_compareReducer,
  reports_compare_clients: reports_compare_clientsReducer,
  reports_compare_accounts: reports_compare_accountsReducer,
  reports_sales_day: reports_sales_dayReducer,
  reports_accounts_day: reports_accounts_dayReducer,
  reports_clients_day: reports_clients_dayReducer,
  reports_accounts_area: reports_accounts_areaReducer,
  reports_clients_area: reports_clients_areaReducer,
  reports_factura_sales: reports_factura_salesReducer,
});