import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Hidden from '@material-ui/core/Hidden';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    widthTab: {
        minWidth: '33%'
    }
  }));

class Waiters extends Component {

    componentDidMount() { 
    }
  
    render() {
        const {waiter, accountsWaiter, clientsWaiter} = this.props;

        let optionsSmall = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Ventas por Meseros'
            },
            xAxis: {
                categories: ['Meseros']
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Ventas'
                }
            },
            credits: {
                enabled: false
            }
        }

        let optionsSmallAccounts = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Cuentas por Meseros'
            },
            xAxis: {
                categories: ['Meseros']
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Cuentas'
                }
            },
            credits: {
                enabled: false
            }
        }

        let optionsSmallClients = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Clientes por Meseros'
            },
            xAxis: {
                categories: ['Meseros']
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Clientes'
                }
            },
            credits: {
                enabled: false
            }
        }

        let options = {
            chart: {
                type: 'column',
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            title: {
                text: 'Ventas por Meseros'
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            },
            xAxis: {
                categories: ['Meseros']
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Ventas'
                }
            },
            credits: {
                enabled: false
            }
        }

        let optionsAccounts = {
            chart: {
                type: 'column',
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            title: {
                text: 'Cuentas por Meseros'
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            },
            xAxis: {
                categories: ['Meseros']
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Cuentas'
                }
            },
            credits: {
                enabled: false
            }
        }

        let optionsClients = {
            chart: {
                type: 'column',
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            title: {
                text: 'Clientes por Meseros'
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            },
            xAxis: {
                categories: ['Meseros']
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Clientes'
                }
            },
            credits: {
                enabled: false
            }
        }

        var results = [];
        if(waiter){
            if(waiter.sales_waiter) {
                let sales_waiter = JSON.parse(JSON.stringify(waiter.sales_waiter));
                const dataObject= sales_waiter.map((data) => {
                    return data.data[0];
                });

                const aggregatedDataObject = [];
                const data_sales_waiter = [];

                dataObject.forEach(function(location){
                    location.forEach(function(element){
                        aggregatedDataObject.push(element)
                    })
                })

                results = aggregatedDataObject.reduce(function(res, obj) {
                    if (!(obj.nombre_mesero in res))
                        res.__array.push(res[obj.nombre_mesero] = obj);
                    else {
                        res[obj.nombre_mesero].total_facturado += obj.total_facturado;
                    }
                    return res;
                }, {__array:[]}).__array
                                .sort(function(a,b) { return b.total_facturado - a.total_facturado; });

                results.forEach(function(element){
                    data_sales_waiter.push({
                        name: element.nombre_mesero,
                        data: [parseFloat(Number(element.total_facturado).toFixed(2))]
                    })
                })



                options = {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Meseros'
                    },
                    xAxis: {
                        categories: ['Meseros']
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Ventas'
                        }
                    },
                    legend:{
                        align: 'right',
                        verticalAlign:'middle',
                        width: 300,
                        itemWidth: 300
                    },
                    plotOptions: {
                        column: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: data_sales_waiter
                }

                optionsSmall = {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Meseros'
                    },
                    xAxis: {
                        categories: ['Meseros']
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Ventas'
                        }
                    },
                    plotOptions: {
                        column: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: data_sales_waiter
                }
            }
        }


        var resultsAccounts = [];
        if(accountsWaiter){
            if(accountsWaiter.accounts_waiter) {
                let accounts_waiter = JSON.parse(JSON.stringify(accountsWaiter.accounts_waiter));
                const dataObjectAccount= accounts_waiter.map((data) => {
                    return data.data[0];
                });

                const aggregatedDataObjectAccount = [];
                const data_account_waiter = [];

                dataObjectAccount.forEach(function(location){
                    location.forEach(function(element){
                        aggregatedDataObjectAccount.push(element)
                    })
                })

                resultsAccounts = aggregatedDataObjectAccount.reduce(function(res, obj) {
                    if (!(obj.nombre_mesero in res))
                        res.__array.push(res[obj.nombre_mesero] = obj);
                    else {
                        res[obj.nombre_mesero].cuentas += obj.cuentas;
                    }
                    return res;
                }, {__array:[]}).__array
                                .sort(function(a,b) { return b.cuentas - a.cuentas; });

                resultsAccounts.forEach(function(element){
                    data_account_waiter.push({
                        name: element.nombre_mesero,
                        data: [parseInt(Number(element.cuentas))]
                    })
                })



                optionsAccounts = {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Cuentas por Meseros'
                    },
                    xAxis: {
                        categories: ['Meseros']
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Cuentas'
                        }
                    },
                    legend:{
                        align: 'right',
                        verticalAlign:'middle',
                        width: 300,
                        itemWidth: 300
                    },
                    plotOptions: {
                        column: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: data_account_waiter
                }

                optionsSmallAccounts = {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Cuentas por Meseros'
                    },
                    xAxis: {
                        categories: ['Meseros']
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Cuentas'
                        }
                    },
                    plotOptions: {
                        column: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: data_account_waiter
                }
            }
        }

        var resultsClients = [];
        if(clientsWaiter){
            if(clientsWaiter.clients_waiter) {
                let clients_waiter = JSON.parse(JSON.stringify(clientsWaiter.clients_waiter));
                const dataObjectClients= clients_waiter.map((data) => {
                    return data.data[0];
                });

                const aggregatedDataObjectClients = [];
                const data_clients_waiter = [];

                dataObjectClients.forEach(function(location){
                    location.forEach(function(element){
                        aggregatedDataObjectClients.push(element)
                    })
                })

                resultsClients = aggregatedDataObjectClients.reduce(function(res, obj) {
                    if (!(obj.nombre_mesero in res))
                        res.__array.push(res[obj.nombre_mesero] = obj);
                    else {
                        res[obj.nombre_mesero].clientes += obj.clientes;
                    }
                    return res;
                }, {__array:[]}).__array
                                .sort(function(a,b) { return b.clientes - a.clientes; });

                resultsClients.forEach(function(element){
                    data_clients_waiter.push({
                        name: element.nombre_mesero,
                        data: [parseInt(Number(element.clientes))]
                    })
                })



                optionsClients = {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Clientes por Meseros'
                    },
                    xAxis: {
                        categories: ['Meseros']
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Clientes'
                        }
                    },
                    legend:{
                        align: 'right',
                        verticalAlign:'middle',
                        width: 300,
                        itemWidth: 300
                    },
                    plotOptions: {
                        column: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: data_clients_waiter
                }

                optionsSmallClients = {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Clientes por Meseros'
                    },
                    xAxis: {
                        categories: ['Meseros']
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Clientes'
                        }
                    },
                    plotOptions: {
                        column: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: data_clients_waiter
                }
            }
        }

        const RenderWaiters = () => {
            const classes = useStyles();
            const [value, setValue] = React.useState(0);
            const handleChange = (event, newValue) => {
                setValue(newValue);
            };

            return (
                <div style={{ textAlign: 'center' }}>

                    <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab className={classes.widthTab} label="Ventas" {...a11yProps(0)} />
                        <Tab className={classes.widthTab} label="Cuentas" {...a11yProps(1)} />
                        <Tab className={classes.widthTab} label="Comensales" {...a11yProps(2)} />
                    </Tabs>
                    </AppBar>
                    
                    
                    <TabPanel value={value} index={0}>
                    <Hidden smDown>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                        </Hidden>
                        <Hidden mdUp>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsSmall}
                        />
                    </Hidden>
                    </TabPanel>


                    <TabPanel value={value} index={1}>
                    <Hidden smDown>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsAccounts}
                        />
                        </Hidden>
                        <Hidden mdUp>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsSmallAccounts}
                        />
                    </Hidden>
                    </TabPanel>


                    <TabPanel value={value} index={2}>
                    <Hidden smDown>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsClients}
                        />
                        </Hidden>
                        <Hidden mdUp>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsSmallClients}
                        />
                    </Hidden>
                    </TabPanel>
                
                </div>
            );
        };

        // Render principal
        return (
        
            <RenderWaiters />
        );

    }

}


function mapStateToProps(state) {
    return {
        waiter: state.reports_waiter,
        accountsWaiter: state.reports_accounts_waiter,
        clientsWaiter: state.reports_clients_waiter
    }
}

export default connect(mapStateToProps)(Waiters);