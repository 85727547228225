
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'TOTAL_SALES':
            copy.sale_total = undefined;

            let data = action.payload_total_sales;
            let venta = 0;
            copy.data_sale_total = data;

            data.forEach(function(element){
              venta = venta + element.sales[0][0].venta_total
            })

            copy.sale_total=venta;
            copy.sale_total_refresh = false;

          return copy
        case 'SALES_COST':
            copy.total_cost = undefined;
            

            let data_cost = action.payload_sales_cost;
            let venta_cost = 0;
            copy.data_total_cost = data_cost;

            data_cost.forEach(function(element){
              venta_cost = venta_cost + element.cost[0][0].costos_totales
            })

            copy.total_cost=venta_cost;
            copy.total_cost_refresh = false;

          return copy
        
        default:
            return state;
      }
  }