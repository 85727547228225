import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'



class SalesCost extends Component {

    componentDidMount() {
    }
  
    render() {
        const {saleCost} = this.props;

        let options = {
            chart: {
                type: 'bar',
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            title: {
                text: 'Ventas'
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total de ventas y costos por Sucursal'
                }
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                animation: false,
                series: {
                    stacking: 'normal',
                    animation: {
                        duration: 0
                    }
                }
            },
            credits: {
                enabled: false
            },
            series: []
        }
        if(saleCost){
            if((saleCost.sale_total) && (saleCost.total_cost || saleCost.total_cost === 0)){

                let sales = saleCost.data_sale_total;
                let costs = saleCost.data_total_cost;

                let categories = [];
                let series = []

                var data_sales = [];
                var data_cost = [];

                sales.forEach(function(element){
                    categories.push(element.sucursal);
                    data_sales.push(element.sales[0][0].venta_total)
                })

                costs.forEach(function(element){
                    data_cost.push(element.cost[0][0].costos_totales)
                })

                //1) combine the arrays:
                var list = [];
                for (var j = 0; j < data_sales.length; j++) 
                    list.push({'sale': data_sales[j], 'cost': data_cost[j], 'category': categories[j]});

                //2) sort:
                list.sort(function(a, b) {
                    return ((a.sale > b.sale) ? -1 : ((a.sale === b.sale) ? 0 : 1));
                });

                //3) separate them back out:
                for (var k = 0; k < list.length; k++) {
                    data_sales[k] = list[k].sale;
                    data_cost[k] = list[k].cost;
                    categories[k] = list[k].category;
                }

                series.push({
                    name: 'Ventas',
                    data: data_sales
                });
                series.push({
                    name: 'Costos',
                    data: data_cost
                });

                // console.log("Ventas: ", series);


                options = {
                    chart: {
                        type: 'bar',
                        animation: false
                    },
                    title: {
                        text: 'Ventas'
                    },
                    xAxis: {
                        categories: categories
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Total de ventas y costos por Sucursal'
                        }
                    },
                    tooltip: {
                        valueDecimals: 2,
                    },
                    legend: {
                        reversed: true
                    },
                    plotOptions: {
                        // animation: false,
                        series: {
                            stacking: 'normal',
                            // animation: {
                            //     duration: 0
                            // }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: series
                }
            }
        }

        const RenderSalesCost = () => {
            return (
                <div style={{ textAlign: 'center' }}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
                </div>
            );
        };

        // Render principal
        return (
        
            <RenderSalesCost />
        );
    }
};

function mapStateToProps(state) {
    return {
        saleCost: state.reports_sales
    }
}

export default connect(mapStateToProps)(SalesCost);