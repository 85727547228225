import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import 'moment/locale/es';
import * as actions from '../../actions';

// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';


const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
        margin: theme.spacing(1),
      
    },
    input: {
        display: 'none',
    },
    whiteNoWrap:{
        color: '#FFF',
        whiteSpace: 'nowrap',
        marginLeft: '-12px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 185,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
  })
);



class FilterButtons extends Component {

    componentDidMount() {
      }

      
      

    render() {
        const RenderFilterButtons = () => {
            const classes = useStyles();
          
            const [startDate, setStartDate] = React.useState(false);
            const [endDate, setEndDate] = React.useState(false);
          
            const [filter, setFilter] = React.useState((localStorage.getItem('filterDate') !== null) ? localStorage.getItem('filterDate') : 'ano');

            // const [filterdetail, setFilterdetail] = React.useState((localStorage.getItem('filterDetail') !== null) ? localStorage.getItem('filterDetail') : 1);


            // const handleChange = event => {
            //     localStorage.setItem('filterDetail', event.target.value);
            //     setFilterdetail(event.target.value);
            // };
          
            function setDateRangeFilter(){
              switch (filter) {
                  case 'hoy':
                      setStartDate(moment().format('YYYY-MM-DD'));
                      setEndDate(moment().format('YYYY-MM-DD'));
                      break;
          
                  case 'ayer':
                      setStartDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
                      setEndDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
                      break;
          
                  case 'semana':
                      setStartDate(moment().startOf('week').format('YYYY-MM-DD'));
                      setEndDate(moment().endOf('week').format('YYYY-MM-DD'));
                      break;
          
                  case 'mes':
                      setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
                      setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
                      break;
          
                  case 'ano':
                      setStartDate(moment().startOf('year').format('YYYY-MM-DD'));
                      setEndDate(moment().endOf('year').format('YYYY-MM-DD'));
                      break;
                  case 'periodo':
                      
                      break;
                  default:
                      setStartDate(moment().format('YYYY-MM-DD'));
                      setEndDate(moment().format('YYYY-MM-DD'));
                }
            }
          
            function setDatesFinals(){
                if ((startDate) && (endDate)){
                    localStorage.setItem('startDate', startDate);
                    localStorage.setItem('endDate', endDate);
                }
            }
          
            function handleClickFilter(filterClick){
              setFilter(filterClick);
              localStorage.setItem('filterDate', filterClick);

              return true;
            }
          
            React.useEffect(setDateRangeFilter, [filter]);
          
            React.useEffect(setDatesFinals, [startDate, endDate]);
          
            return (
              <Grid container direction="row" justify="center" alignItems="center">
                      <Button data-key={1} variant="contained" className={classes.button} 
                          onClick={() => {
                              // Filtrar las gráficas al día de hoy
                              handleClickFilter('hoy');
                              this.props.dispatch(actions.get_reports('hoy'));
                          }}
                      >
                          Ventas
                      </Button>
                      <Button data-key={2} variant="contained" className={classes.button} 
                          onClick={() => {
                              // Filtrar las gráficas al día de ayer
                              handleClickFilter('ayer');
                              this.props.dispatch(actions.get_reports('ayer'));
                          }}      
                      >
                          Inventario
                      </Button>
                      
                      
              </Grid>
            );
          };

        return (
            <Hidden smDown>
                <RenderFilterButtons />
            </Hidden>
            );
    }
}

function mapStateToProps(state) {
    return {state}
}
  
export default connect(mapStateToProps)(FilterButtons);