
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'ACCOUNTS_HOUR':

            const accounts_hour = action.payload_accounts_hour;
            copy.accounts_hour = JSON.parse(JSON.stringify(accounts_hour));

          return copy
        
        default:
            return state;
      }
  }