
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'TOTAL_CLIENTS':

          let data_total_clients = action.payload_total_clients;
          let total_clients = 0;

          copy.data_total_clients = data_total_clients;

          data_total_clients.forEach(function(element){
            total_clients = total_clients + element.clients[0][0].clientes_totales
          })

          copy.total_clients=total_clients;

        return copy
        
        default:
            return state;
      }
  }