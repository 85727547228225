import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
    Card,
    CardContent,
    Typography,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    AppBar,
    Tabs,
    Tab,
    Box
  } from '@material-ui/core';

import moment from 'moment';
import 'moment/locale/es';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
    card: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    header: {
        alignItems: 'center'
    },
    widthTab: {
        minWidth: '33%'
    }
}));

function currencyFormat(num) {
  return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

class Compare extends Component {

  componentDidMount() { 
  }

  render() {
    var percentaje_average = undefined;
    var previous_average = 0;
    var percentaje_average_clients = undefined;
    var previous_average_clients = 0;
    var percentaje_average_accounts = undefined;
    var previous_average_accounts = 0;
    const {comparePrevious, comparePreviousAccounts, comparePreviousClients} = this.props;

    if(comparePrevious){
      if((comparePrevious.compare_previous_period) && (comparePrevious.compare_previous_period_year)){

          previous_average = (comparePrevious.compare_previous_period.previo + comparePrevious.compare_previous_period_year.previo_ano) / 2

          percentaje_average = (comparePrevious.compare_previous_period.actual / previous_average) - 1;
      }
    }

    if(comparePreviousClients){
        if((comparePreviousClients.compare_previous_period) && (comparePreviousClients.compare_previous_period_year)){
  
            previous_average_clients = (comparePreviousClients.compare_previous_period.previo + comparePreviousClients.compare_previous_period_year.previo_ano) / 2
  
            percentaje_average_clients = (comparePreviousClients.compare_previous_period.actual / previous_average) - 1;
        }
      }

    if(comparePreviousAccounts){
        if((comparePreviousAccounts.compare_previous_period) && (comparePreviousAccounts.compare_previous_period_year)){
  
            previous_average_accounts = (comparePreviousAccounts.compare_previous_period.previo + comparePreviousAccounts.compare_previous_period_year.previo_ano) / 2
  
            percentaje_average_accounts = (comparePreviousAccounts.compare_previous_period.actual / previous_average) - 1;
        }
      }

    const RenderCompare = () => {

        const classes = useStyles();
        const [value, setValue] = React.useState(0);
        const handleChange = (event, newValue) => {
            setValue(newValue);
        };
    
      return (
        <div style={{ textAlign: 'center' }}>
            <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab className={classes.widthTab} label="Ventas" {...a11yProps(0)} />
                <Tab className={classes.widthTab} label="Cuentas" {...a11yProps(1)} />
                <Tab className={classes.widthTab} label="Comensales" {...a11yProps(2)} />
            </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>

                <Card className={classes.card}>
                    <Grid container className={classes.root} spacing={2}>

                        <Grid item xs={12}  md={4}>
                            <CardContent style={{textAlign: 'center'}}>
                                <Typography className={classes.title} style={{whiteSpace: 'nowrap'}}>
                                Periodo Anterior
                                </Typography>
                                
                                
                                {(() => {
                                    if(comparePrevious){
                                        if(comparePrevious.compare_previous_period){
                                            // console.log("Current: ", this.props.state.reports.compare_previous_period.percentaje_current);
                                        
                                            if(comparePrevious.compare_previous_period.percentaje_current >= 0){
                                            
                                                return(
                                                    <Typography component="h2" style={{color: '#008000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                        <ArrowUpwardIcon style={{color: '#008000', fontSize: '3rem'}}/>
                                                        <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt(comparePrevious.compare_previous_period.percentaje_current * 100)}%</span>
                                                    </Typography>                
                                                );
                                            }else{
                                                return(
                                                <Typography component="h2" style={{color: '#ff0000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                    <ArrowDownwardIcon style={{color: '#ff0000', fontSize: '3rem'}}/>
                                                    <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt(comparePrevious.compare_previous_period.percentaje_current * -100)}%</span>
                                                </Typography>
                                                );
                                            }
                                        }
                                    }

                                })()}
                            </CardContent>
                        </Grid>

                        <Grid item xs={12}  md={8}>
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                            <Typography className={classes.title} >
                                            <b>{(comparePrevious) ? (comparePrevious.compare_previous_period) ? currencyFormat(comparePrevious.compare_previous_period.previo):'$' + 0 :'$' + 0}</b> 
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePrevious){
                                                    if(comparePrevious.compare_previous_period){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePrevious.compare_previous_period.dates.start_date_vs).format("DD/MM/YYYY")} - {moment(comparePrevious.compare_previous_period.dates.end_date_vs).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}
                                        </TableRow>

                                        <TableRow>

                                            <TableCell>
                                            <Typography className={classes.title} >
                                                <b>{(comparePrevious) ? (comparePrevious.compare_previous_period) ? currencyFormat(comparePrevious.compare_previous_period.actual):'$' + 0 :'$' + 0}</b> 
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePrevious){
                                                    if(comparePrevious.compare_previous_period){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePrevious.compare_previous_period.dates.start_date).format("DD/MM/YYYY")} - {moment(comparePrevious.compare_previous_period.dates.end_date).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Grid>

                    </Grid>

                    <Divider />

                    <Grid container className={classes.root} spacing={2}>

                        <Grid item xs={12}  md={4}>
                            <CardContent style={{textAlign: 'center'}}>
                                <Typography className={classes.title} style={{whiteSpace: 'nowrap'}}>
                                Año anterior
                                </Typography>
                                
                                {(() => {
                                    if(comparePrevious){
                                        if(comparePrevious.compare_previous_period_year){
                                            // console.log("Current: ", this.props.state.reports.compare_previous_period_year.percentaje_current_year);
                                        
                                            if(comparePrevious.compare_previous_period_year.percentaje_current_year >= 0){
                                            
                                                return(
                                                    <Typography component="h2" style={{color: '#008000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                        <ArrowUpwardIcon style={{color: '#008000', fontSize: '3rem'}}/>
                                                        <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt(comparePrevious.compare_previous_period_year.percentaje_current_year * 100)}%</span>
                                                    </Typography>                
                                                );
                                            }else{
                                                return(
                                                <Typography component="h2" style={{color: '#ff0000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                    <ArrowDownwardIcon style={{color: '#ff0000', fontSize: '3rem'}}/>
                                                    <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt(comparePrevious.compare_previous_period_year.percentaje_current_year * -100)}%</span>
                                                </Typography>
                                                );
                                            }
                                        }
                                    }

                                })()}
                            </CardContent>
                        </Grid>

                        <Grid item xs={12}  md={8}>
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                            <Typography className={classes.title} >
                                            <b>{(comparePrevious) ? (comparePrevious.compare_previous_period_year) ? currencyFormat(comparePrevious.compare_previous_period_year.previo_ano):'$' + 0 :'$' + 0}</b> 
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePrevious){
                                                    if(comparePrevious.compare_previous_period_year){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePrevious.compare_previous_period_year.dates.start_date_vs).format("DD/MM/YYYY")} - {moment(comparePrevious.compare_previous_period_year.dates.end_date_vs).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}
                                        </TableRow>
                                        <TableRow>

                                            <TableCell>
                                            <Typography className={classes.title} >
                                            <b>{(comparePrevious) ? (comparePrevious.compare_previous_period_year) ? currencyFormat(comparePrevious.compare_previous_period_year.actual):'$' + 0 :'$' + 0}</b>  
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePrevious){
                                                    if(comparePrevious.compare_previous_period_year){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePrevious.compare_previous_period_year.dates.start_date).format("DD/MM/YYYY")} - {moment(comparePrevious.compare_previous_period_year.dates.end_date).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Grid>

                    </Grid>

                    <Divider />

                    <Grid container className={classes.root} spacing={2}>

                        <Grid item xs={12}  md={4}>
                            <CardContent style={{textAlign: 'center'}}>
                                <Typography className={classes.title} style={{whiteSpace: 'nowrap'}}>
                                Promedio
                                </Typography>
                                
                                {(() => {
                                    if(comparePrevious){
                                        if((comparePrevious.compare_previous_period_year) && (percentaje_average)){
                                        
                                            if(percentaje_average >= 0){
                                            
                                                return(
                                                    <Typography component="h2" style={{color: '#008000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                        <ArrowUpwardIcon style={{color: '#008000', fontSize: '3rem'}}/>
                                                        <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt( percentaje_average * 100 )}%</span>
                                                    </Typography>                
                                                );
                                            }else{
                                                return(
                                                <Typography component="h2" style={{color: '#ff0000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                    <ArrowDownwardIcon style={{color: '#ff0000', fontSize: '3rem'}}/>
                                                    <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt( percentaje_average * -100 )}%</span>
                                                </Typography>
                                                );
                                            }
                                        }
                                    }

                                })()}
                            </CardContent>
                        </Grid>

                        <Grid item xs={12}  md={8}>
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>

                                            <TableCell>
                                            <Typography className={classes.title} >
                                                <b>{currencyFormat( previous_average )}</b> 
                                            </Typography>
                                            </TableCell>
                                            <TableCell>
                                            <Typography className={classes.title} >
                                                Promedio
                                            </Typography>
                                            </TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                            <Typography className={classes.title} >
                                            <b>{(comparePrevious) ? (comparePrevious.compare_previous_period_year) ? currencyFormat(comparePrevious.compare_previous_period_year.actual):'$' + 0 :'$' + 0}</b>
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePrevious){
                                                    if(comparePrevious.compare_previous_period_year){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePrevious.compare_previous_period_year.dates.start_date).format("DD/MM/YYYY")} - {moment(comparePrevious.compare_previous_period_year.dates.end_date).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Grid>

                    </Grid>

                </Card>


            </TabPanel>

            <TabPanel value={value} index={1}>

                <Card className={classes.card}>
                    <Grid container className={classes.root} spacing={2}>

                        <Grid item xs={12}  md={4}>
                            <CardContent style={{textAlign: 'center'}}>
                                <Typography className={classes.title} style={{whiteSpace: 'nowrap'}}>
                                Periodo Anterior
                                </Typography>
                                
                                
                                {(() => {
                                    if(comparePreviousAccounts){
                                        if(comparePreviousAccounts.compare_previous_period){
                                            // console.log("Current: ", this.props.state.reports.compare_previous_period.percentaje_current);
                                        
                                            if(comparePreviousAccounts.compare_previous_period.percentaje_current >= 0){
                                            
                                                return(
                                                    <Typography component="h2" style={{color: '#008000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                        <ArrowUpwardIcon style={{color: '#008000', fontSize: '3rem'}}/>
                                                        <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt(comparePreviousAccounts.compare_previous_period.percentaje_current * 100)}%</span>
                                                    </Typography>                
                                                );
                                            }else{
                                                return(
                                                <Typography component="h2" style={{color: '#ff0000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                    <ArrowDownwardIcon style={{color: '#ff0000', fontSize: '3rem'}}/>
                                                    <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt(comparePreviousAccounts.compare_previous_period.percentaje_current * -100)}%</span>
                                                </Typography>
                                                );
                                            }
                                        }
                                    }

                                })()}
                            </CardContent>
                        </Grid>

                        <Grid item xs={12}  md={8}>
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                            <Typography className={classes.title} >
                                            <b>{(comparePreviousAccounts) ? (comparePreviousAccounts.compare_previous_period) ? currencyFormat(comparePreviousAccounts.compare_previous_period.previo): 0 : 0}</b> 
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePreviousAccounts){
                                                    if(comparePreviousAccounts.compare_previous_period){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePreviousAccounts.compare_previous_period.dates.start_date_vs).format("DD/MM/YYYY")} - {moment(comparePreviousAccounts.compare_previous_period.dates.end_date_vs).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}
                                        </TableRow>

                                        <TableRow>

                                            <TableCell>
                                            <Typography className={classes.title} >
                                                <b>{(comparePreviousAccounts) ? (comparePreviousAccounts.compare_previous_period) ? currencyFormat(comparePreviousAccounts.compare_previous_period.actual): 0 : 0}</b> 
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePreviousAccounts){
                                                    if(comparePreviousAccounts.compare_previous_period){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePreviousAccounts.compare_previous_period.dates.start_date).format("DD/MM/YYYY")} - {moment(comparePreviousAccounts.compare_previous_period.dates.end_date).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Grid>

                    </Grid>

                    <Divider />

                    <Grid container className={classes.root} spacing={2}>

                        <Grid item xs={12}  md={4}>
                            <CardContent style={{textAlign: 'center'}}>
                                <Typography className={classes.title} style={{whiteSpace: 'nowrap'}}>
                                Año anterior
                                </Typography>
                                
                                {(() => {
                                    if(comparePreviousAccounts){
                                        if(comparePreviousAccounts.compare_previous_period_year){
                                            // console.log("Current: ", this.props.state.reports.compare_previous_period_year.percentaje_current_year);
                                        
                                            if(comparePreviousAccounts.compare_previous_period_year.percentaje_current_year >= 0){
                                            
                                                return(
                                                    <Typography component="h2" style={{color: '#008000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                        <ArrowUpwardIcon style={{color: '#008000', fontSize: '3rem'}}/>
                                                        <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt(comparePreviousAccounts.compare_previous_period_year.percentaje_current_year * 100)}%</span>
                                                    </Typography>                
                                                );
                                            }else{
                                                return(
                                                <Typography component="h2" style={{color: '#ff0000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                    <ArrowDownwardIcon style={{color: '#ff0000', fontSize: '3rem'}}/>
                                                    <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt(comparePreviousAccounts.compare_previous_period_year.percentaje_current_year * -100)}%</span>
                                                </Typography>
                                                );
                                            }
                                        }
                                    }

                                })()}
                            </CardContent>
                        </Grid>

                        <Grid item xs={12}  md={8}>
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                            <Typography className={classes.title} >
                                            <b>{(comparePreviousAccounts) ? (comparePreviousAccounts.compare_previous_period_year) ? currencyFormat(comparePreviousAccounts.compare_previous_period_year.previo_ano): 0 : 0}</b> 
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePreviousAccounts){
                                                    if(comparePreviousAccounts.compare_previous_period_year){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePreviousAccounts.compare_previous_period_year.dates.start_date_vs).format("DD/MM/YYYY")} - {moment(comparePreviousAccounts.compare_previous_period_year.dates.end_date_vs).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}
                                        </TableRow>
                                        <TableRow>

                                            <TableCell>
                                            <Typography className={classes.title} >
                                            <b>{(comparePreviousAccounts) ? (comparePreviousAccounts.compare_previous_period_year) ? currencyFormat(comparePreviousAccounts.compare_previous_period_year.actual): 0 : 0}</b>  
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePreviousAccounts){
                                                    if(comparePreviousAccounts.compare_previous_period_year){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePreviousAccounts.compare_previous_period_year.dates.start_date).format("DD/MM/YYYY")} - {moment(comparePreviousAccounts.compare_previous_period_year.dates.end_date).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Grid>

                    </Grid>

                    <Divider />

                    <Grid container className={classes.root} spacing={2}>

                        <Grid item xs={12}  md={4}>
                            <CardContent style={{textAlign: 'center'}}>
                                <Typography className={classes.title} style={{whiteSpace: 'nowrap'}}>
                                Promedio
                                </Typography>
                                
                                {(() => {
                                    if(comparePreviousAccounts){
                                        if((comparePreviousAccounts.compare_previous_period_year) && (percentaje_average_accounts)){
                                        
                                            if(percentaje_average_accounts >= 0){
                                            
                                                return(
                                                    <Typography component="h2" style={{color: '#008000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                        <ArrowUpwardIcon style={{color: '#008000', fontSize: '3rem'}}/>
                                                        <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt( percentaje_average_accounts * 100 )}%</span>
                                                    </Typography>                
                                                );
                                            }else{
                                                return(
                                                <Typography component="h2" style={{color: '#ff0000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                    <ArrowDownwardIcon style={{color: '#ff0000', fontSize: '3rem'}}/>
                                                    <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt( percentaje_average_accounts * -100 )}%</span>
                                                </Typography>
                                                );
                                            }
                                        }
                                    }

                                })()}
                            </CardContent>
                        </Grid>

                        <Grid item xs={12}  md={8}>
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>

                                            <TableCell>
                                            <Typography className={classes.title} >
                                                <b>{currencyFormat( previous_average_accounts )}</b> 
                                            </Typography>
                                            </TableCell>
                                            <TableCell>
                                            <Typography className={classes.title} >
                                                Promedio
                                            </Typography>
                                            </TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                            <Typography className={classes.title} >
                                            <b>{(comparePreviousAccounts) ? (comparePreviousAccounts.compare_previous_period_year) ? currencyFormat(comparePreviousAccounts.compare_previous_period_year.actual): 0 : 0}</b>
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePreviousAccounts){
                                                    if(comparePreviousAccounts.compare_previous_period_year){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePreviousAccounts.compare_previous_period_year.dates.start_date).format("DD/MM/YYYY")} - {moment(comparePreviousAccounts.compare_previous_period_year.dates.end_date).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Grid>

                    </Grid>

                </Card>


            </TabPanel>

            <TabPanel value={value} index={2}>

                <Card className={classes.card}>
                    <Grid container className={classes.root} spacing={2}>

                        <Grid item xs={12}  md={4}>
                            <CardContent style={{textAlign: 'center'}}>
                                <Typography className={classes.title} style={{whiteSpace: 'nowrap'}}>
                                Periodo Anterior
                                </Typography>
                                
                                
                                {(() => {
                                    if(comparePreviousClients){
                                        if(comparePreviousClients.compare_previous_period){
                                            // console.log("Current: ", this.props.state.reports.compare_previous_period.percentaje_current);
                                        
                                            if(comparePreviousClients.compare_previous_period.percentaje_current >= 0){
                                            
                                                return(
                                                    <Typography component="h2" style={{color: '#008000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                        <ArrowUpwardIcon style={{color: '#008000', fontSize: '3rem'}}/>
                                                        <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt(comparePreviousClients.compare_previous_period.percentaje_current * 100)}%</span>
                                                    </Typography>                
                                                );
                                            }else{
                                                return(
                                                <Typography component="h2" style={{color: '#ff0000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                    <ArrowDownwardIcon style={{color: '#ff0000', fontSize: '3rem'}}/>
                                                    <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt(comparePreviousClients.compare_previous_period.percentaje_current * -100)}%</span>
                                                </Typography>
                                                );
                                            }
                                        }
                                    }

                                })()}
                            </CardContent>
                        </Grid>

                        <Grid item xs={12}  md={8}>
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                            <Typography className={classes.title} >
                                            <b>{(comparePreviousClients) ? (comparePreviousClients.compare_previous_period) ? currencyFormat(comparePreviousClients.compare_previous_period.previo): 0 : 0}</b> 
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePreviousClients){
                                                    if(comparePreviousClients.compare_previous_period){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePreviousClients.compare_previous_period.dates.start_date_vs).format("DD/MM/YYYY")} - {moment(comparePreviousClients.compare_previous_period.dates.end_date_vs).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}
                                        </TableRow>

                                        <TableRow>

                                            <TableCell>
                                            <Typography className={classes.title} >
                                                <b>{(comparePreviousClients) ? (comparePreviousClients.compare_previous_period) ? currencyFormat(comparePreviousClients.compare_previous_period.actual): 0 : 0}</b> 
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePreviousClients){
                                                    if(comparePreviousClients.compare_previous_period){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePreviousClients.compare_previous_period.dates.start_date).format("DD/MM/YYYY")} - {moment(comparePreviousClients.compare_previous_period.dates.end_date).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Grid>

                    </Grid>

                    <Divider />

                    <Grid container className={classes.root} spacing={2}>

                        <Grid item xs={12}  md={4}>
                            <CardContent style={{textAlign: 'center'}}>
                                <Typography className={classes.title} style={{whiteSpace: 'nowrap'}}>
                                Año anterior
                                </Typography>
                                
                                {(() => {
                                    if(comparePreviousClients){
                                        if(comparePreviousClients.compare_previous_period_year){
                                            // console.log("Current: ", this.props.state.reports.compare_previous_period_year.percentaje_current_year);
                                        
                                            if(comparePreviousClients.compare_previous_period_year.percentaje_current_year >= 0){
                                            
                                                return(
                                                    <Typography component="h2" style={{color: '#008000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                        <ArrowUpwardIcon style={{color: '#008000', fontSize: '3rem'}}/>
                                                        <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt(comparePreviousClients.compare_previous_period_year.percentaje_current_year * 100)}%</span>
                                                    </Typography>                
                                                );
                                            }else{
                                                return(
                                                <Typography component="h2" style={{color: '#ff0000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                    <ArrowDownwardIcon style={{color: '#ff0000', fontSize: '3rem'}}/>
                                                    <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt(comparePreviousClients.compare_previous_period_year.percentaje_current_year * -100)}%</span>
                                                </Typography>
                                                );
                                            }
                                        }
                                    }

                                })()}
                            </CardContent>
                        </Grid>

                        <Grid item xs={12}  md={8}>
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                            <Typography className={classes.title} >
                                            <b>{(comparePreviousClients) ? (comparePreviousClients.compare_previous_period_year) ? currencyFormat(comparePreviousClients.compare_previous_period_year.previo_ano): 0 : 0}</b> 
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePreviousClients){
                                                    if(comparePreviousClients.compare_previous_period_year){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePreviousClients.compare_previous_period_year.dates.start_date_vs).format("DD/MM/YYYY")} - {moment(comparePreviousClients.compare_previous_period_year.dates.end_date_vs).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}
                                        </TableRow>
                                        <TableRow>

                                            <TableCell>
                                            <Typography className={classes.title} >
                                            <b>{(comparePreviousClients) ? (comparePreviousClients.compare_previous_period_year) ? currencyFormat(comparePreviousClients.compare_previous_period_year.actual): 0 : 0}</b>  
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePreviousClients){
                                                    if(comparePreviousClients.compare_previous_period_year){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePreviousClients.compare_previous_period_year.dates.start_date).format("DD/MM/YYYY")} - {moment(comparePreviousClients.compare_previous_period_year.dates.end_date).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Grid>

                    </Grid>

                    <Divider />

                    <Grid container className={classes.root} spacing={2}>

                        <Grid item xs={12}  md={4}>
                            <CardContent style={{textAlign: 'center'}}>
                                <Typography className={classes.title} style={{whiteSpace: 'nowrap'}}>
                                Promedio
                                </Typography>
                                
                                {(() => {
                                    if(comparePreviousClients){
                                        if((comparePreviousClients.compare_previous_period_year) && (percentaje_average_clients)){
                                        
                                            if(percentaje_average_clients >= 0){
                                            
                                                return(
                                                    <Typography component="h2" style={{color: '#008000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                        <ArrowUpwardIcon style={{color: '#008000', fontSize: '3rem'}}/>
                                                        <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt( percentaje_average_clients * 100 )}%</span>
                                                    </Typography>                
                                                );
                                            }else{
                                                return(
                                                <Typography component="h2" style={{color: '#ff0000', fontSize: '3rem', whiteSpace: 'nowrap'}} gutterBottom>
                                                    <ArrowDownwardIcon style={{color: '#ff0000', fontSize: '3rem'}}/>
                                                    <span style={{verticalAlign: 'text-bottom', textDecoration: 'underline'}}>{parseInt( percentaje_average_clients * -100 )}%</span>
                                                </Typography>
                                                );
                                            }
                                        }
                                    }

                                })()}
                            </CardContent>
                        </Grid>

                        <Grid item xs={12}  md={8}>
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>

                                            <TableCell>
                                            <Typography className={classes.title} >
                                                <b>{currencyFormat( previous_average_clients )}</b> 
                                            </Typography>
                                            </TableCell>
                                            <TableCell>
                                            <Typography className={classes.title} >
                                                Promedio
                                            </Typography>
                                            </TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                            <Typography className={classes.title} >
                                            <b>{(comparePreviousClients) ? (comparePreviousClients.compare_previous_period_year) ? currencyFormat(comparePreviousClients.compare_previous_period_year.actual): 0 : 0}</b>
                                            </Typography>
                                            </TableCell>
                                            {(() => {
                                                if(comparePreviousClients){
                                                    if(comparePreviousClients.compare_previous_period_year){
                                                        return(
                                                        <TableCell>
                                                        <Typography className={classes.title} >
                                                        {moment(comparePreviousClients.compare_previous_period_year.dates.start_date).format("DD/MM/YYYY")} - {moment(comparePreviousClients.compare_previous_period_year.dates.end_date).format("DD/MM/YYYY")}
                                                        </Typography>
                                                        </TableCell>
                                                        )
                                                    }
                                                }

                                            })()}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Grid>

                    </Grid>

                </Card>


            </TabPanel>

        </div>
        
      );
    };


    // Render principal
    return (
      
        <RenderCompare />
    );
  }

}

function mapStateToProps(state) {
    return {
      comparePrevious: state.reports_compare,
      comparePreviousAccounts: state.reports_compare_accounts,
      comparePreviousClients: state.reports_compare_clients
    }
  }

export default connect(mapStateToProps)(Compare);