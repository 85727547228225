
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'COMPARE_PREVIOUS_PERIOD_ACCOUNTS':

                copy.compare_previous_period = undefined;
    
                let data_compare_previous_period = action.payload_compare_previous_period_accounts;
                let venta_total_current = 0;
                let venta_total_previous_period = 0;
                let percentaje_current = 0;

                data_compare_previous_period.forEach(function(element){
                  venta_total_current = venta_total_current + element.sales[0][0].venta_total
                })
                
                data_compare_previous_period.forEach(function(element){
                  venta_total_previous_period = venta_total_previous_period + element.sales[0][0].venta_total_previa
                })

                percentaje_current = (venta_total_current / venta_total_previous_period) - 1;

                copy.compare_previous_period = {
                  actual: venta_total_current,
                  previo: venta_total_previous_period,
                  dates: data_compare_previous_period[0].dates,
                  percentaje_current: percentaje_current
                };
    
              return copy
            case 'COMPARE_PREVIOUS_PERIOD_YEAR_ACCOUNTS':

                copy.compare_previous_period_year = undefined;

                let data_compare_previous_period_year = action.payload_compare_previous_period_year_accounts;
                let venta_total_current_year = 0;
                let venta_total_previous_period_year = 0;
                let percentaje_current_year = 0;

                data_compare_previous_period_year.forEach(function(element){
                  venta_total_current_year = venta_total_current_year + element.sales[0][0].venta_total;
                })
                
                data_compare_previous_period_year.forEach(function(element){
                  venta_total_previous_period_year = venta_total_previous_period_year + element.sales[0][0].venta_total_ano_pasado;
                })

                percentaje_current_year = (venta_total_current_year / venta_total_previous_period_year) - 1;

                copy.compare_previous_period_year = {
                  actual: venta_total_current_year,
                  previo_ano: venta_total_previous_period_year,
                  dates: data_compare_previous_period_year[0].dates,
                  percentaje_current_year: percentaje_current_year
                };
    
              return copy
            default:
                return state;
      }
  }