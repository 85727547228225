import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {
    Typography,
    AppBar,
    Tabs,
    Tab,
    Box
  } from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    widthTab: {
        minWidth: '33%'
    }
  }));

class TotalSales extends Component {

    componentDidMount() { 
    }
  
    render() {

        const {dataSales, sales_totals, total_clients} = this.props;

        let options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            title: {
                text: 'Contribución por Sucursal'
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            credits: {
                enabled: false
            }
        }

        let optionsAccounts = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            title: {
                text: 'Contribución por Sucursal'
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            credits: {
                enabled: false
            }
        }

        let optionsClients = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                events: {
                    load() {
                      this.showLoading();
                    }
                }
            },
            title: {
                text: 'Contribución por Sucursal'
            },
            loading:{
                hideDuration: 100,
                labelStyle: {
                    "fontWeight": "bold", 
                    "position": "relative", 
                    "top": "45%"
                },
                showDuration: 100,
                style: {
                    "position": "absolute", 
                    "backgroundColor": "#ffffff", 
                    "opacity": 0.5, 
                    "textAlign": "center"
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            credits: {
                enabled: false
            }
        }


        if(dataSales){
            if(dataSales.sale_total) {
                let sales = dataSales.data_sale_total;
                var data_sales = [];

                sales.forEach(function(element){
                    data_sales.push({
                        name: element.sucursal,
                        y: element.sales[0][0].venta_total
                    })
                })
                options = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        animation: false
                    },
                    title: {
                        text: 'Contribución por Sucursal'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    exporting: {
                        showTable: false,
                        csv: {
                            columnHeaderFormatter: function(item, key) {
                                if (!item || item instanceof Highcharts.Axis) {
                                    return 'Sucursal'
                                } else {
                                    return item.name;
                                }
                            }
                        }, // https://code.highcharts.com/modules/export-data.src.js REVISAR getCellHTMLFromValue
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_sales
                    }]
                }
            }
        }

        if(sales_totals){
            if(sales_totals.data_sales_totals) {
                let accounts = sales_totals.data_sales_totals;
                var data_accounts = [];

                accounts.forEach(function(element){
                    data_accounts.push({
                        name: element.sucursal,
                        y: element.accounts[0][0].cuentas_totales
                    })
                })


                optionsAccounts = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        animation: false
                    },
                    title: {
                        text: 'Contribución por Sucursal'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_accounts
                    }]
                }
            }
        }

        if(total_clients){
            if(total_clients.data_total_clients) {
                let clients = total_clients.data_total_clients;
                var data_clients = [];

                clients.forEach(function(element){
                    data_clients.push({
                        name: element.sucursal,
                        y: element.clients[0][0].clientes_totales
                    })
                })


                optionsClients = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        animation: false
                    },
                    title: {
                        text: 'Contribución por Sucursal'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: 'Total',
                        colorByPoint: true,
                        data: data_clients
                    }]
                }
            }
        }

        const RenderTotalSales = () => {
            const classes = useStyles();
            const [value, setValue] = React.useState(0);
            const handleChange = (event, newValue) => {
                setValue(newValue);
            };
            return (
                <div style={{ textAlign: 'center' }}>
                    <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab className={classes.widthTab} label="Ventas" {...a11yProps(0)} />
                        <Tab className={classes.widthTab} label="Cuentas" {...a11yProps(1)} />
                        <Tab className={classes.widthTab} label="Comensales" {...a11yProps(2)} />
                    </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsAccounts}
                    />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsClients}
                    />
                    </TabPanel>
                </div>
            );
            };


        // Render principal
        return (
        
            <RenderTotalSales />
        );        
    }
}
  

function mapStateToProps(state) {
    return {
        dataSales: state.reports_sales,
        sales_totals: state.reports_average_account,
        total_clients: state.reports_average_consume
    }
}

export default connect(mapStateToProps)(TotalSales);