
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'SALES_WAITER':

            const sales_waiter = action.payload_waiter;
            copy.sales_waiter = JSON.parse(JSON.stringify(sales_waiter));

          return copy
        
        default:
            return state;
      }
  }