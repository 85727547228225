
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'CANCEL_SALES':
            copy.cancel_sales = undefined;

            let cancel_sales = action.payload_cancel_sales;
            var dataObject_cancel= cancel_sales.map((data) => {
                return data.data[0];
            });

            var data_cancel_sales = [];

            let aggregatedDataObject_cancel = [];

            dataObject_cancel.forEach(function(location){
                location.forEach(function(element){
                  aggregatedDataObject_cancel.push(element)
                })
            })

            data_cancel_sales.push({
                name: 'Descuentos',
                y: aggregatedDataObject_cancel.reduce((acc, c) => {
                    return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.descuentos_totales).toFixed(2)) || 0);
                  }, 0) || 0
            });
            data_cancel_sales.push({
                name: 'Cortesías',
                y: aggregatedDataObject_cancel.reduce((acc, c) => {
                    return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.cortesias_totales).toFixed(2)) || 0);
                  }, 0) || 0
            });
            data_cancel_sales.push({
                name: 'Cancelaciones',
                y: aggregatedDataObject_cancel.reduce((acc, c) => {
                    return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.cancelaciones_totales).toFixed(2)) || 0);
                  }, 0) || 0
            });
            data_cancel_sales.push({
                name: 'Anulaciones',
                y: aggregatedDataObject_cancel.reduce((acc, c) => {
                    return (parseFloat(Number(acc).toFixed(2)) || 0) + (parseFloat(Number(c.anulaciones_totales).toFixed(2)) || 0);
                  }, 0) || 0
            });



            copy.cancel_sales=data_cancel_sales;

          return copy
        
        default:
            return state;
      }
  }