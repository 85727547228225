
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'TOP_WORST_DRINKS':
            copy.top_drink = undefined;
            copy.worst_drink = undefined;
            const top_worst_drink = action.payload_drink;
            copy.data_drink = JSON.parse(JSON.stringify(top_worst_drink));

            let dataObject_drink= top_worst_drink.map((data) => {
              return data.data[0];
              })

            let dataObject_drink_worst= top_worst_drink.map((data) => {
              return data.data_worst[0];
              })
              
            let aggregatedDataObject_drink = []
            let aggregatedDataObject_drink_worst = []
            
            dataObject_drink.forEach(function(location){
                location.forEach(function(element){
                    aggregatedDataObject_drink.push(element)
                })
            })

            dataObject_drink_worst.forEach(function(location){
                location.forEach(function(element){
                    aggregatedDataObject_drink_worst.push(element)
                })
            })
            
            let results_drink = aggregatedDataObject_drink.reduce(function(res, obj) {
                if (!(obj.codigo in res))
                    res.__array.push(res[obj.codigo] = obj);
                else {
                    res[obj.codigo].cantidad += obj.cantidad;
                    res[obj.codigo].total_facturado += obj.total_facturado;
                }
                return res;
            }, {__array:[]}).__array
                            .sort(function(a,b) { return b.total_facturado - a.total_facturado; });

            let results_drink_worst = aggregatedDataObject_drink_worst.reduce(function(res, obj) {
                if (!(obj.codigo in res))
                    res.__array.push(res[obj.codigo] = obj);
                else {
                    res[obj.codigo].cantidad += obj.cantidad;
                    res[obj.codigo].total_facturado += obj.total_facturado;
                }
                return res;
            }, {__array:[]}).__array
                            .sort(function(a,b) { return a.total_facturado - b.total_facturado; });

            copy.top_drink=results_drink;
            copy.worst_drink=results_drink_worst;

          return copy
        
        default:
            return state;
      }
  }