
export default function(state = null, action) {
    let copy = Object.assign({}, state);
    
    switch (action.type) {
        case 'SALES_PAYMENT_METHOD':

            const sales_payment_method = action.payload_payment;
            copy.sales_payment_method=JSON.parse(JSON.stringify(sales_payment_method));

          return copy
        
        default:
            return state;
      }
  }