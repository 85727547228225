import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import {red, grey} from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import * as actions from '../actions';

const muiTheme = createMuiTheme({
    palette: {
        primary: red,
        secondary: grey
    }
  });

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://ambit.com.mx/">
        Ambit Technology
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    );
  }

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

class Login extends Component {

    componentDidMount() { 
    }
  
    render() {

        const {loginState} = this.props;
        if(loginState){
          if(!loginState.error){
            return(
              <Redirect to="/"/>
              )            
          }
        }

        const RenderLogin = () => {
          const classes = useStyles();
          const [email, setEmail] = React.useState("");
          const [password, setPassword] = React.useState("");
          const handleChangeEmail = (event) => {
              const {value} = event.target;
              setEmail(value);
          };
          
          const handleChangePassword = (event) => {
              const {value} = event.target;
              setPassword(value);
          };

            return (
                <ThemeProvider theme={muiTheme}>
                    <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <div>
                        <img src="logo.png" alt="Software para Restaurantes y Tiendas, POS, Soporte IT" />
                        </div>
                        <Typography component="h1" variant="h5">
                        Iniciar Sesión
                        </Typography>
                        {/* <form className={classes.form} noValidate> */}
                        <form className={classes.form} noValidate onSubmit={(e) => {
                                        e.preventDefault();
                                        this.props.login(email, password);
                                    }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo electrónico"
                            name="email"
                            value={email}
                            onChange={handleChangeEmail}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            value={password}
                            onChange={handleChangePassword}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Entrar
                        </Button>
                        {/* <Grid container>
                            <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                            </Grid>
                            <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                            </Grid>
                        </Grid> */}
                        </form>
                    </div>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                    </Container>
                    </ThemeProvider>
            );
        };


        // Render principal
        return (
        
            <RenderLogin />
        );        
    }
}

function mapStateToProps(state) {
  return {
      loginState: state.auth
  }
}

export default connect(mapStateToProps, actions)(Login);